import React, { useEffect, useRef, useState } from "react";
import { JoiningScreen } from "./components/JoiningScreen";
// import { useTheme } from "@material-ui/styles";
// import { useMediaQuery } from "@material-ui/core";
import { useUserAuth } from "./hooks/useAuthContextProvider";

const Main = () => {
  const [micOn, setMicOn] = useState(true);
  const [webcamOn, setWebcamOn] = useState(true);

  // const theme = useTheme();
  // const isXStoSM = useMediaQuery(theme.breakpoints.only("xs"));

  // useEffect(() => {
  //   if (isXStoSM) {
  //     window.onbeforeunload = () => {
  //       return "Are you sure you want to exit?";
  //     };
  //   }
  // }, [isXStoSM]);

  return (
    <>
      <JoiningScreen
        setMicOn={setMicOn}
        micEnabled={micOn}
        webcamEnabled={webcamOn}
        setWebcamOn={setWebcamOn}
      />
    </>
  );
};

export default Main;
