import {
  Box,
  Button,
  useTheme,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
  // IconButton,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { VideocamOff, MicOff, Mic, Videocam } from "@material-ui/icons";
import useResponsiveSize from "../utils/useResponsiveSize";
import { red } from "@material-ui/core/colors";
import { useUserAuth } from "../hooks/useAuthContextProvider";
import NewLatest from "./latest/newLatest";
import { useDispatch, useSelector } from "react-redux";
import { addMeetingIdToFirebase, getClassDaysForStudent, getClassDaysForTeacher } from "../redux/classes/classesActions";
import Navbar from "./navbar/navbar";
import ClassListItem from "./classListItem";
import { useNavigate } from "react-router-dom";
import { QUERY_PARAM_AUTH_TOKEN, QUERY_PARAM_PREVIEW_AS_ROLE } from "../common/constants";
import getToken from "../services/tokenService";
import { useTokenEndpoint } from "./AppData/useUISettings";
import { getUserToken } from "../services/tokenService";
import { v4 } from "uuid";
import { useAVToggle, useHMSActions, usePreviewJoin } from "@100mslive/react-sdk";
import { useSearchParam } from "react-use"

const useStyles = makeStyles((theme) => ({
  video: {
    borderRadius: "10px",
    height: "100%",
    width: "100%",
    objectFit: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  toggleButton: {
    borderRadius: "100%",
    minWidth: "auto",
    width: "44px",
    height: "44px",
  },
  previewBox: {
    width: "100%",
    height: "45vh",
    position: "relative",
  },
}));

export function JoiningScreen({
  micEnabled,
  webcamEnabled,
  setWebcamOn,
  setMicOn,
}: any) {
  const { user, userDetails } = useUserAuth();
  const theme = useTheme();
  const classes = useStyles();

  const navigate = useNavigate()

  const [setting, setSetting] = useState("video");
  const [{ webcams, mics }, setDevices] = useState<any>({
    devices: [],
    webcams: [],
    mics: [],
  });

  const [videoTrack, setVideoTrack] = useState<any>(null);

  const videoPlayerRef = useRef<any>();
  const popupVideoPlayerRef = useRef<any>();

  const videoTrackRef = useRef<any>();
  const audioTrackRef = useRef<any>();

  const [settingDialogueOpen, setSettingDialogueOpen] = useState(false);

  const tokenEndpoint = useTokenEndpoint()

  const [audioTrack, setAudioTrack] = useState<any>(null);
  let authToken = useSearchParam(QUERY_PARAM_AUTH_TOKEN)

  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetails?.userType === 2) {
      dispatch(getClassDaysForTeacher({ teacherId: user?.uid }))
    } else if (userDetails?.userType === 3) {
      dispatch(getClassDaysForStudent({ studentId: user?.uid }))
    }
  }, [userDetails]);
  const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle()
  const hmsActions = useHMSActions()
  const savePreferenceAndJoin = useCallback((role: any, roomId: any, classId: any, classDayId: any, hasPaid: any, className: any) => {
    if (authToken) {
      return;
    }
    if (!tokenEndpoint || !roomId) {
      return;
    }
    const getTokenFn: any = !role
      ? () => getUserToken(v4())
      : () => getToken(tokenEndpoint, v4(), role, roomId);
    getTokenFn()
      .then(async (token: any) => {
        await hmsActions.join({
          userName: userDetails?.name,
          authToken: token
        })
        onJoin && onJoin(role, roomId, classId, classDayId, hasPaid, className);
      })
      .catch((error: any) => {
        console.log(error)
        // setError({ title: "no", body: "wrong" });
      });
  }, [
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    userDetails?.name,
    setPreviewPreference,
    // onJoin,
  ]);

  const onJoin = (role: any, roomId: any, classId: any, classDayId: any, hasPaid: any, className: any) => {
    let meetingURL = `/meeting/${roomId}`;
    if (role) {
      meetingURL += `/${role}`;
    }
    navigate(meetingURL, {
      state: {
        classId: classId,
        classDayId: classDayId,
        hasPaid: hasPaid,
        className: className
      }
    });
  };

  const {
    getClassDaysForTeacherResp,
    isGettingClassDaysForTeacher,
    getClassDaysForTeacherRespStatus,
    getClassDaysForTeacherError,
    getClassDaysForTeacherErrorMsg,
    getClassDaysForStudentResp,
    isGettingClassDaysForStudent,
    getClassDaysForStudentRespStatus,
    getClassDaysForStudentError,
    getClassDaysForStudentErrorMsg,
  } = useSelector((state: any) => state.classes);

  const handleClickOpen = () => {
    setSettingDialogueOpen(true);
  };

  const handleClose = (value: any) => {
    setSettingDialogueOpen(false);
  };
  const isXSOnly = useMediaQuery(theme.breakpoints.only("xs"));
  const isXLOnly = useMediaQuery(theme.breakpoints.only("xl"));

  const webcamOn = useMemo(() => !!videoTrack, [videoTrack]);
  const micOn = useMemo(() => !!audioTrack, [audioTrack]);

  const _handleTurnOffWebcam = () => {
    const videoTrack = videoTrackRef.current;

    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(null);
      setWebcamOn(false);
    }
  };
  const _handleTurnOnWebcam = () => {
    const videoTrack = videoTrackRef.current;

    if (!videoTrack) {
      setWebcamOn(true);
    }
  };

  const _toggleWebcam = () => {
    const videoTrack = videoTrackRef.current;

    if (videoTrack) {
      _handleTurnOffWebcam();
    } else {
      _handleTurnOnWebcam();
    }
  };
  const _handleTurnOffMic = () => {
    const audioTrack = audioTrackRef.current;

    if (audioTrack) {
      audioTrack.stop();

      setAudioTrack(null);
      setMicOn(false);
    }
  };
  const _handleTurnOnMic = () => {
    const audioTrack = audioTrackRef.current;

    if (!audioTrack) {
      setMicOn(true);
    }
  };
  const _handleToggleMic = () => {
    const audioTrack = audioTrackRef.current;

    if (audioTrack) {
      _handleTurnOffMic();
    } else {
      _handleTurnOnMic();
    }
  };

  const getDefaultMediaTracks = async ({ mic = true, webcam = true }: any) => {
    if (mic) {
      const audioConstraints = {
        audio: true,
      };

      const stream = await navigator.mediaDevices.getUserMedia(
        audioConstraints
      );
      const audioTracks = stream.getAudioTracks();

      const audioTrack = audioTracks.length ? audioTracks[0] : null;

      setAudioTrack(audioTrack);
    }

    if (webcam) {
      const videoConstraints = {
        video: {
          width: 1280,
          height: 720,
        },
      };

      const stream = await navigator.mediaDevices.getUserMedia(
        videoConstraints
      );
      const videoTracks = stream.getVideoTracks();

      const videoTrack = videoTracks.length ? videoTracks[0] : null;
      setVideoTrack(videoTrack);
    }
  };

  const getDevices = async ({ micEnabled, webcamEnabled }: any) => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const webcams = devices.filter((d) => d.kind === "videoinput");
      const mics = devices.filter((d) => d.kind === "audioinput");

      const hasMic = mics.length > 0;
      const hasWebcam = webcams.length > 0;

      setDevices({ webcams, mics, devices });
      getDefaultMediaTracks({
        mic: hasMic && micEnabled,
        webcam: hasWebcam && webcamEnabled,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const spacingHorizontalTopicsObject = {
    xl: 60,
    lg: 40,
    md: 40,
    sm: 40,
    xs: 32,
  };

  useEffect(() => {
    audioTrackRef.current = audioTrack;
  }, [audioTrack]);

  useEffect(() => {
    videoTrackRef.current = videoTrack;

    if (videoTrack) {
      const videoSrcObject = new MediaStream([videoTrack]);

      if (videoPlayerRef.current) {
        videoPlayerRef.current.srcObject = videoSrcObject;
        videoPlayerRef.current.play();
      }

      setTimeout(() => {
        if (popupVideoPlayerRef.current) {
          popupVideoPlayerRef.current.srcObject = videoSrcObject;
          popupVideoPlayerRef.current.play();
        }
      }, 1000);
    } else {
      if (videoPlayerRef.current) {
        videoPlayerRef.current.srcObject = null;
      }
      if (popupVideoPlayerRef.current) {
        popupVideoPlayerRef.current.srcObject = null;
      }
    }
  }, [videoTrack, setting, settingDialogueOpen]);

  useEffect(() => {
    if (userDetails?.userType === 2) {
      getDevices({ micEnabled, webcamEnabled });
    }
  }, [userDetails]);

  const handleJoinMeetingWithId = async (data: any) => {
    // navigate("/meeting/63e2585bcd8175701aac02a9/paid-student")
    savePreferenceAndJoin(data.role, data.roomId, data.classId, data.classDayId, data.hasPaid, data.className)
    // const token = await getToken();
    // const meetingId = await createMeeting({
    //   token: token
    // })
    // const valid = await validateMeeting({
    //   roomId: meetingId,
    //   token,
    // });
    // dispatch(addMeetingIdToFirebase({
    //   classDayId: classDayId,
    //   meetingId: meetingId
    // }))

    // if (valid) {
    //   setToken(token);
    //   setMeetingId(meetingId);
    //   if (videoTrack) {
    //     videoTrack.stop();
    //     setVideoTrack(null);
    //   }
    //   onClickStartMeeting();
    //   setParticipantName("");
    // } else alert("Invalid Meeting Id");
  }

  return (
    <div className="custom-bg">
      <Navbar />
      <div className="min-h-[calc(100vh-100px)] overflow-y-auto flex flex-col justify-center items-center">
        <div className="grid grid-cols-1 md:grid-cols-9 p-10">
          {/* <div className="md:col-span-1">
          </div> */}
          <div className="h-full flex justify-center md:col-span-3 items-center">
            <div className="bg-white bg-opacity-70 text-black-500 text-lg p-10 px-3 rounded-lg w-full">
              <div className="text-center font-bold text-3xl mb-10">
                Live Classes
              </div>
              <div className="grid grid-cols-4 gap-4 px-3 py-3">
                <div className="col-span-2 text-2xl">
                  Today
                </div>
              </div>
              {userDetails?.userType === 2 ? getClassDaysForTeacherRespStatus === 200 && getClassDaysForTeacherResp.map((day: any) => {
                return (
                  <ClassListItem key={day.classId} day={day} handleJoinMeetingWithId={handleJoinMeetingWithId} isTeacher={userDetails?.userType === 2} />
                )
              }) : getClassDaysForStudentRespStatus === 200 && getClassDaysForStudentResp.map((day: any) => {
                return (
                  <ClassListItem key={day.classId} day={day} handleJoinMeetingWithId={handleJoinMeetingWithId} isTeacher={userDetails?.userType === 2} />
                )
              })}
            </div>
          </div>
          <div className="col-span-1">

          </div>
          {userDetails?.userType === 2 ? <div className="mt-5 md:mt-0 text-black-500 text-lg bg-white bg-opacity-70 rounded rounded-lg md:col-span-4 flex-col">
            <Box
              style={{
                width: isXSOnly ? "100%" : "",
                height: "100%"
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%"
                }}
              >
                <div className="h-full">
                  <div className="w-full min-h-[40vh] h-full relative">
                    <video
                      autoPlay
                      playsInline
                      muted
                      ref={videoPlayerRef}
                      controls={false}
                      className={classes.video + " flip"}
                    />

                    {!isXSOnly ? (
                      <div className="absolute flex justify-center items-center top-0 bottom-0 left-0 right-0">
                        {!webcamOn ? (
                          <Typography variant={isXLOnly ? "h4" : "h6"} className="text-black">
                            The camera is off
                          </Typography>
                        ) : null}
                      </div>
                    ) : null}

                    <Box
                      position="absolute"
                      bottom={theme.spacing(2)}
                      left="0"
                      right="0"
                    >
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        className="justify-center md:justify-start md:pl-4"
                      >
                        <Grid item>
                          <Tooltip
                            title={micOn ? "Turn off mic" : "Turn on mic"}
                            arrow
                            placement="top"
                          >
                            <Button
                              onClick={() => _handleToggleMic()}
                              variant="contained"
                              style={
                                micOn
                                  ? {}
                                  : {
                                    backgroundColor: red[500],
                                    color: "white",
                                  }
                              }
                              className={classes.toggleButton}
                            >
                              {micOn ? <Mic /> : <MicOff />}
                            </Button>
                          </Tooltip>
                        </Grid>

                        <Grid item>
                          <Tooltip
                            title={
                              webcamOn ? "Turn off camera" : "Turn on camera"
                            }
                            arrow
                            placement="top"
                          >
                            <Button
                              onClick={() => _toggleWebcam()}
                              variant="contained"
                              style={
                                webcamOn
                                  ? {}
                                  : {
                                    backgroundColor: red[500],
                                    color: "white",
                                  }
                              }
                              className={classes.toggleButton}
                            >
                              {webcamOn ? <Videocam /> : <VideocamOff />}
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </div>
              </div>
            </Box>
          </div> : <>
            <div className="mt-5 md:mt-0 text-black-500 text-lg rounded-lg md:col-span-5">
              <NewLatest />
            </div></>}
        </div>
      </div>
    </div>
  );
}
function setPreviewPreference(arg0: { name: any; isAudioMuted: boolean; isVideoMuted: boolean; }) {
  throw new Error("Function not implemented.");
}

