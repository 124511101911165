import {
  GET_POLLS_INIT,
  GET_POLLS_SUCCESS,
  GET_POLLS_FAILURE,
  ADD_POLLS_INIT,
  ADD_POLLS_SUCCESS,
  ADD_POLLS_FAILURE,
  END_POLL_INIT,
  END_POLL_SUCCESS,
  END_POLL_FAILURE,
  RESET_POLL,
} from "../actionTypes";

export const getPollsSuccess = (data: any) => {
  return {
    type: GET_POLLS_SUCCESS,
    data
  }
}

export const getPollsFailure = (error: any) => {
  return {
    type: GET_POLLS_FAILURE,
    error
  }
}

export const getPolls = (params: any) => {
  const payload = { params };
  return {
    type: GET_POLLS_INIT,
    payload,
  }
}

export const addPollsSuccess = (data: any) => {
  return {
    type: ADD_POLLS_SUCCESS,
    data
  }
}

export const addPollsFailure = (error: any) => {
  return {
    type: ADD_POLLS_FAILURE,
    error
  }
}

export const addPolls = (params: any) => {
  const payload = { params };
  return {
    type: ADD_POLLS_INIT,
    payload,
  }
}

export const endPollSuccess = (data: any) => {
  return {
    type: END_POLL_SUCCESS,
    data
  }
}

export const endPollFailure = (error: any) => {
  return {
    type: END_POLL_FAILURE,
    error
  }
}

export const endPoll = (params: any) => {
  const payload = { params };
  return {
    type: END_POLL_INIT,
    payload,
  }
}

export const resetPoll = (params: any) => {
  const payload = { params }
  return {
    type: RESET_POLL,
    payload
  }
}