import axios from 'axios';

export const getRequest = async (url: string) => {
  try {
    const data = await axios.get(url)
      .then((resp) => resp.data)
      .catch((err) => getErrorResponse(err));
    return data;
  } catch (e) {
    return e;
  }
}

export const postRequest = async (url: string, postData: any, headers: any) => {
  try {
    const data = await axios.post(url, postData, { headers: headers })
      .then((resp) => resp.data)
      .catch((err) => {
        return {
          error: true,
          status: err?.response?.status,
          message: err.response?.data?.message || err.message,
        }
      });
    return data;
  } catch (e) {
    return e;
  }
}

export const putRequest = async (url: string, postData: any) => {
  try {
    const data = await axios.put(url, postData)
      .then((resp) => resp.data)
      .catch((err) => getErrorResponse(err));
    return data;
  } catch (e) {
    return e;
  }
}

const getErrorResponse = (err: any) => {
  let errorMsg = 'Something went wrong';
  if (err.response) {
    if (err.response.status === 401) {
    } else if (err.response.status === 500) {
      errorMsg = 'Internal server error, try again later';
    } else if (err.response.status > 500) {
      errorMsg = 'Something went wrong, try again later';
    }
  }
  return {
    error: true,
    message: errorMsg,
  };
};