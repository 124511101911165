import React, { useState } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { Modal, Backdrop } from '@material-ui/core';
import { storage } from '../firebaseConfig';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addDocumentsToClassDay } from '../redux/classes/classesActions';

const FileUpload = ({
  open,
  setOpen,
  hmsActions,
  redirectToLeavePage
}: any) => {
  const [files, setFiles] = useState<File[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [uploadComplete, setUploadComplete] = useState<boolean>(false);
  const [downloadUrls, setDownloadUrls] = useState<string[]>([]);

  const dispatch = useDispatch()

  const location = useLocation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    const fileArray: File[] = [];

    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        fileArray.push(fileList[i]);
      }
      setFiles(fileArray);
    }
  };

  const handleUpload = () => {
    let completedUploads = 0;
    const urls: string[] = [];

    setOpen(true);

    const final: any = []

    files.forEach((file) => {
      const storageRef = ref(storage, `LiveClass/${location.state.classId}/${location.state.classDayId}/documents/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(percentage);
        },
        (error) => {
          console.log(error);
        },
        async () => {
          const doUrl = await getDownloadURL(uploadTask.snapshot.ref);
          final.push({
            documentType: file.type,
            name: file.name,
            url: doUrl
          })
          urls.push(doUrl);

          completedUploads++;
          if (completedUploads === files.length) {
            setUploadComplete(true);
            setOpen(false);
            hmsActions.leave()
            redirectToLeavePage()
            setDownloadUrls(urls);
            dispatch(addDocumentsToClassDay({
              classDayId: location.state.classDayId,
              data: final
            }))
          }
        }
      );
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
      >
        <div className="bg-white bg-opacity-70 rounded-[10px] w-fit absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] p-5">
          <h2 className="text-center font-bold text-xl mb-4 mt-3">Upload Files</h2>
          <input type="file" onChange={handleChange} multiple />
          <div className="flex gap-2 mt-5">
            <button className="rounded-[10px] px-4 py-2 bg-[white]" onClick={handleUpload}>Upload</button>
            <button className="outline-none border-none bg-[red] text-white rounded-[10px] px-4 py-2" onClick={() => {
              hmsActions.leave()
              redirectToLeavePage()
            }}>Skip</button>
          </div>
          {progress > 0 && <p className="text-center">Progress: {progress}%</p>}
          {uploadComplete && <p className="text-center">Upload complete!</p>}
        </div>
      </Modal>
    </>
  );
};

export default FileUpload;
