import {
  put, call,
  takeLatest,
} from 'redux-saga/effects';
import { db } from '../../firebaseConfig';
import { collection, getDocs, query, where, doc, getDoc, updateDoc, setDoc, addDoc, deleteDoc } from "firebase/firestore";
import {
  GET_CLASS_DAYS_FOR_TEACHER_INIT,
  GET_CLASS_DAYS_FOR_STUDENT_INIT,
  ADD_HLS_LINK_TO_FIREBASE_INIT,
  REMOVE_HLS_LINK_FROM_FIREBASE_INIT,
  GET_HLS_LINK_FOR_CLASS_INIT,
  GET_CMS_DATA_INIT,
  SEND_PUSH_NOTIFICATION_INIT,
  SEND_PUSH_NOTIFICATION_TO_TOPIC_INIT,
  GET_CLASS_WITH_CLASS_ID_INIT,
  GET_WEEK_CLASS_DAYS_FOR_TEACHER_INIT,
  GET_WEEK_CLASS_DAYS_FOR_ADMIN_INIT,
  GET_SUBJECTS_INIT,
  GET_SUBJECT_MENTOR_INIT,
  GET_MENTOR_INIT,
  ADD_CLASS_DAY_INIT,
  UPDATE_CLASS_DAY_INIT,
  GET_CLASS_DAY_INIT,
  ADD_USER_TIMESTAMP_INIT,
  ADD_USER_LEAVE_TIMESTAMP_INIT,
  DELETE_CLASS_DAY_INIT,
  CREDIT_COINS_FOR_STUDENTS_INIT,
  UPLOAD_RECORDING_TO_DRIVE_INIT,
  ADD_MEETING_ID_TO_FIREBASE_INIT,
  ADD_DOCUMENTS_TO_CLASS_DAY_INIT
} from '../actionTypes';
import {
  getClassDaysForTeacherSuccess,
  getClassDaysForTeacherFailure,
  getClassDaysForStudentSuccess,
  getClassDaysForStudentFailure,
  addHlsLinkToFirebaseSuccess,
  addHlsLinkToFirebaseFailure,
  removeHlsLinkFromFirebaseSuccess,
  removeHlsLinkFromFirebaseFailure,
  getHlsLinkForClassSuccess,
  getHlsLinkForClassFailure,
  getCmsDataSuccess,
  getCmsDataFailure,
  sendPushNotificationSuccess,
  sendPushNotificationFailure,
  sendPushNotificationToTopicSuccess,
  sendPushNotificationToTopicFailure,
  getClassWithClassIdSuccess,
  getClassWithClassIdFailure,
  getWeekClassDaysForTeacherSuccess,
  getWeekClassDaysForTeacherFailure,
  getWeekClassDaysForAdminSuccess,
  getWeekClassDaysForAdminFailure,
  getSubjectsSuccess,
  getSubjectsFailure,
  getSubjectMentorSuccess,
  getSubjectMentorFailure,
  getMentorSuccess,
  getMentorFailure,
  addClassDaySuccess,
  addClassDayFailure,
  updateClassDaySuccess,
  updateClassDayFailure,
  getClassDaySuccess,
  getClassDayFailure,
  addUserTimestampSuccess,
  addUserTimestampFailure,
  addUserLeaveTimestampSuccess,
  addUserLeaveTimestampFailure,
  deleteClassDaySuccess,
  deleteClassDayFailure,
  creditCoinsForStudentsSuccess,
  creditCoinsForStudentsFailure,
  uploadRecordingToDriveSuccess,
  uploadRecordingToDriveFailure,
  addMeetingIdToFirebaseSuccess,
  addMeetingIdToFirebaseFailure,
  addDocumentsToClassDaySuccess,
  addDocumentsToClassDayFailure
} from './classesActions';
import { getRequest, postRequest } from '../../utils/apiRequests';
import axios from 'axios';
import { baseURL, endPoints } from '../../utils/apiEndPoints';

function* getClassDaysForTeacher(action: any): any {
  try {
    const classDays = async () => {
      let classRooms: any = [];
      const q = query(collection(db, "Classroom"), where("mentorIds", "array-contains", action.payload.params.teacherId));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        classRooms.push({
          id: doc.id,
          title: doc.data().title,
          meetingId: doc.data().meetingLink,
          teacherId: doc.data().teacherId,
          classId: doc.id,
        });
      })

      let classDays: any = [];
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const nextDate = new Date();
      nextDate.setHours(24, 0, 0, 0);
      const classDayQuery = query(collection(db, "ClassDay"), where("date", ">=", currentDate), where("date", "<=", nextDate));
      const classDayQuerySnapshot = await getDocs(classDayQuery);
      classDayQuerySnapshot.forEach((doc) => {
        for (let i = 0; i < classRooms.length; i++) {
          if (doc.data().classId === classRooms[i].id && doc.data().mentorId === action.payload.params.teacherId) {
            classDays.push({
              [classRooms[i].title]: doc.data(),
              meetingId: classRooms[i].meetingId,
              teacherId: classRooms[i].teacherId,
              classId: classRooms[i].classId,
            });
          }
        }
      })

      return classDays;
    }

    const data = yield call(classDays);
    if (data[0]) {
      yield put(getClassDaysForTeacherSuccess(data));
    } else {
      yield put(getClassDaysForTeacherFailure(data.message));
    }
  } catch (err: any) {
    yield put(getClassDaysForTeacherFailure(err.message));
  }
}

export function* getClassDaysForTeacherSaga() {
  yield takeLatest(GET_CLASS_DAYS_FOR_TEACHER_INIT, getClassDaysForTeacher);
}

function* getClassDaysForStudent(action: any): any {
  try {
    const classDays = async () => {
      let paidClassRooms: any = [];
      let freeClassRooms: any = [];
      let finalClassRooms: any = [];
      let hasPaid = false;
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const nextDate = new Date();
      nextDate.setHours(24, 0, 0, 0);

      const q = query(collection(db, "Classroom"), where("students", "array-contains", action.payload.params.studentId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size > 0) {
        hasPaid = true;
        querySnapshot.forEach((document) => {
          paidClassRooms.push({
            id: document.id,
            title: document.data().title,
            meetingId: document.data().meetingLink,
            teacherId: document.data().teacherId,
            isPaid: true,
            classId: document.id,
          });
        })
      }

      const checkFreeAccess = query(collection(db, "Classroom"), where("hasFreeAccess", "==", true));
      const freeAccessSnapshot = await getDocs(checkFreeAccess);
      freeAccessSnapshot.forEach((document) => {
        freeClassRooms.push({
          id: document.id,
          title: document.data().title,
          meetingId: document.data().meetingLink,
          teacherId: document.data().teacherId,
          isPaid: false,
          classId: document.id,
        });
      })

      if (hasPaid === false) {
        finalClassRooms = freeClassRooms;
      } else {
        let temp = freeClassRooms.concat(paidClassRooms);
        const arrayUniqueByKey = [...new Map(temp.map((item: any) =>
          [item["id"], item])).values()];
        finalClassRooms = arrayUniqueByKey;
      }

      let classDays: any = [];
      const classDayQuery = query(collection(db, "ClassDay"), where("date", ">=", currentDate), where("date", "<=", nextDate));
      const classDayQuerySnapshot = await getDocs(classDayQuery);
      classDayQuerySnapshot.forEach((doc) => {
        for (let i = 0; i < finalClassRooms.length; i++) {
          if (doc.data().classId === finalClassRooms[i].id) {
            classDays.push({
              [finalClassRooms[i].title]: doc.data(),
              meetingId: finalClassRooms[i].meetingId,
              teacherId: finalClassRooms[i].teacherId,
              hasPaid: finalClassRooms[i].isPaid,
              classId: finalClassRooms[i].classId
            });
          }
        }
      })

      // querySnapshot.forEach((doc) => {
      //   classRooms.push({
      //     id: doc.id,
      //     title: doc.data().title,
      //     meetingId: doc.data().meetingId,
      //   });
      // })


      return classDays;
    }

    const data = yield call(classDays);
    if (data[0]) {
      yield put(getClassDaysForStudentSuccess(data));
    } else {
      yield put(getClassDaysForStudentFailure(data.message));
    }
  } catch (err: any) {
    yield put(getClassDaysForStudentFailure(err.message));
  }
}

export function* getClassDaysForStudentSaga() {
  yield takeLatest(GET_CLASS_DAYS_FOR_STUDENT_INIT, getClassDaysForStudent);
}

function* addHlsLinkToFirebase(action: any): any {
  try {
    const add = async () => {
      try {
        const docRef = doc(db, "Classroom", action.payload.params.classId);
        await updateDoc(docRef, {
          hlsLink: action.payload.params.hlsLink,
          isLive: true,
          liveClassId: action.payload.params.classDayId
        });
        const classDayRef = doc(db, "ClassDay", action.payload.params.classDayId)
        const classDayGet = await getDoc(classDayRef)
        const classDayData = classDayGet.data()
        await updateDoc(doc(db, "ClassDay", action.payload.params.classDayId), {
          hlsLink: action.payload.params.hlsLink,
          hlsLinks: [...classDayData?.hlsLinks || "", action.payload.params.hlsLink],
          isLive: true,
        })
        return true
      } catch (e: any) {
        return false
      }
    }

    const data = yield call(add);
    if (data) {
      yield put(addHlsLinkToFirebaseSuccess({
        hlsLink: action.payload.params.hlsLink,
      }))
    } else {
      yield put(addHlsLinkToFirebaseFailure("Something went wrong"))
    }
  } catch (err: any) {
    yield put(addHlsLinkToFirebaseFailure("Something went wrong"));
  }
}

export function* addHlsLinkToFirebaseSaga() {
  yield takeLatest(ADD_HLS_LINK_TO_FIREBASE_INIT, addHlsLinkToFirebase);
}

function* removeHlsLinkFromFirebase(action: any): any {
  try {
    const remove = async () => {
      try {
        const docRef = doc(db, "Classroom", action.payload.params.classId);
        await updateDoc(docRef, {
          hlsLink: "",
          isLive: false,
          liveClassId: ""
        });
        await updateDoc(doc(db, "ClassDay", action.payload.params.classDayId), {
          hlsLink: "",
          isLive: false
        })
        return true;
      } catch (e: any) {
        return false;
      }
    }

    const data = yield call(remove);
    if (data) {
      yield put(removeHlsLinkFromFirebaseSuccess({
        hlsLink: "",
      }));
    } else {
      yield put(removeHlsLinkFromFirebaseFailure("Something went wrong"))
    }
  } catch (err: any) {
    yield put(removeHlsLinkFromFirebaseFailure("Something went wrong"));
  }
}

export function* removeHlsLinkFromFirebaseSaga() {
  yield takeLatest(REMOVE_HLS_LINK_FROM_FIREBASE_INIT, removeHlsLinkFromFirebase);
}

function* getHlsLinkForClass(action: any): any {
  try {
    // const get = async () => {
    //   const options = {
    //     method: "POST",
    //     headers: {
    //       "Authorization": `${action.payload.params.token}`,
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ "roomId": `${action.payload.params.meetingId}` })
    //   };
    //   const url = `https://api.videosdk.live/v2/hls/start`;
    //   const response = await fetch(url, options);
    //   console.log(response)
    // }


    // const data = yield call(get);
    const headers = {
      "Authorization": `${action.payload.params.token}`,
      "Content-Type": "application/json",
    }
    const body = JSON.stringify({
      "roomId": `${action.payload.params.meetingId}`, "config": {
        layout: {
          type: "SPOTLIGHT",
          priority: "SPEAKER",
          gridSize: 1
        }
      }
    });
    const url = `https://api.videosdk.live/v2/hls/start`;
    const data = yield call(postRequest, url, body, headers);
    if (data?.downstreamUrl) {
      yield put(getHlsLinkForClassSuccess(data));
    } else {
      yield put(getHlsLinkForClassFailure("Something went wrong"));
    }
  } catch (err: any) {
    yield put(getHlsLinkForClassFailure(err.message));
  }
}

export function* getHlsLinkForClassSaga() {
  yield takeLatest(GET_HLS_LINK_FOR_CLASS_INIT, getHlsLinkForClass);
}

function* getCmsData(action: any): any {
  try {
    const get = async () => {
      const docRef = doc(db, "WebsiteCMS", "latestAtSnipe");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        return "";
      }
    }

    const data = yield call(get);
    if (data) {
      yield put(getCmsDataSuccess(data));
    } else {
      yield put(getCmsDataFailure("Something went wrong"));
    }
  } catch (err: any) {
    yield put(getCmsDataFailure("Something went wrong"));
  }
}

export function* getCmsDataSaga() {
  yield takeLatest(GET_CMS_DATA_INIT, getCmsData);
}

function* sendPushNotification(action: any): any {
  try {
    const url = `https://us-central1-tsoappnew.cloudfunctions.net/startClass?id=${action.payload.params.classId || ""}`
    const data = yield call(getRequest, url);
    if (data?.downstreamUrl) {
      yield put(sendPushNotificationSuccess(data));
    } else {
      yield put(sendPushNotificationFailure("Something went wrong"));
    }
  } catch (err: any) {
    yield put(sendPushNotificationFailure(err.message));
  }
}

export function* sendPushNotificationSaga() {
  yield takeLatest(SEND_PUSH_NOTIFICATION_INIT, sendPushNotification);
}

function* sendPushNotificationToTopic(action: any): any {
  try {
    const url = `https://us-central1-tsoappnew.cloudfunctions.net/startClass?id=${action.payload.params.classId || ""}`
    const data = yield call(getRequest, url);
    if (data?.downstreamUrl) {
      yield put(sendPushNotificationToTopicSuccess(data));
    } else {
      yield put(sendPushNotificationToTopicFailure("Something went wrong"));
    }
  } catch (err: any) {
    yield put(sendPushNotificationToTopicFailure(err.message));
  }
}

export function* sendPushNotificationToTopicSaga() {
  yield takeLatest(SEND_PUSH_NOTIFICATION_TO_TOPIC_INIT, sendPushNotificationToTopic);
}

function* getClassWithClassId(action: any): any {
  try {
    const get = async () => {
      const temp = query(collection(db, "Classroom"), where("classId", "==", action.payload.params.classId))
      const tempData = await getDocs(temp);
      if (tempData.size > 0) {
        let arr: any = [];
        tempData.forEach((tempQ) => {
          arr.push(tempQ.data())
        })
        return arr[0];
      } else {
        return "";
      }
    }

    const data = yield call(get);
    if (data) {
      yield put(getClassWithClassIdSuccess(data));
    } else {
      yield put(getClassWithClassIdFailure("No Classroom found with that Id"));
    }
  } catch (err: any) {
    yield put(getClassWithClassIdFailure("Something went wrong"));
  }
}

export function* getClassWithClassIdSaga() {
  yield takeLatest(GET_CLASS_WITH_CLASS_ID_INIT, getClassWithClassId);
}

function* getWeekClassDaysForTeacher(action: any): any {
  try {
    const get = async () => {
      const temp = query(collection(db, "Classroom"), where("mentorIds", "array-contains", action.payload.params.teacherId))
      const tempData = await getDocs(temp);
      if (tempData.size > 0) {
        let arr: any = {};
        tempData.forEach((tempQ) => {
          arr[tempQ.id] = tempQ.data().title
        })
        const pDate = action.payload.params.date
        const today = new Date(pDate)
        const date = new Date(today);
        const firstDate = new Date(date.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)))
        const lastDat = new Date(today.setDate(today.getDate() - today.getDay() + 7))
        const classDays = query(collection(db, "ClassDay"), where("date", ">", firstDate), where("date", "<", lastDat))
        const classDaysData = await getDocs(classDays)
        let totalClassDays: any = [];
        classDaysData.forEach((querySnapshot: any) => {
          if (arr[querySnapshot.data().classId] && querySnapshot.data().mentorId === action.payload.params.teacherId) {
            totalClassDays.push(querySnapshot.data())
          }
        })
        let finalData: any = [];
        let sameDateClasses: any = [];
        const check = totalClassDays.sort((a: any, b: any) => a.date.toDate() - b.date.toDate())
        let currentCheckDate = check[0]?.date?.toDate()?.toDateString()
        for (let i = 0; i < check.length; i++) {
          // console.log(check[i].date.toDate().toDateString())
          if (check[i].date.toDate().toDateString() === currentCheckDate) {
            sameDateClasses.push({ ...check[i], subjectName: arr[check[i].classId] })
          } else {
            finalData.push({ [currentCheckDate]: sameDateClasses })
            currentCheckDate = check[i].date.toDate().toDateString();
            sameDateClasses = [{ ...check[i], subjectName: arr[check[i].classId] }]
          }
        }
        if (sameDateClasses.length > 0) {
          finalData.push({ [currentCheckDate]: sameDateClasses })
        }
        return finalData;
      } else {
        return "";
      }
    }

    const data = yield call(get);
    if (data) {
      yield put(getWeekClassDaysForTeacherSuccess(data));
    } else {
      yield put(getWeekClassDaysForTeacherFailure("No Classes found"));
    }
  } catch (err: any) {
    console.log(err)
    yield put(getWeekClassDaysForTeacherFailure("Something went wrong"));
  }
}

export function* getWeekClassDaysForTeacherSaga() {
  yield takeLatest(GET_WEEK_CLASS_DAYS_FOR_TEACHER_INIT, getWeekClassDaysForTeacher);
}

function* getWeekClassDaysForAdmin(action: any): any {
  try {
    const get = async () => {
      const temp = collection(db, "Classroom")
      const tempData = await getDocs(temp);
      if (tempData.size > 0) {
        let arr: any = {};
        tempData.forEach((tempQ) => {
          arr[tempQ.id] = {
            subjectName: tempQ.data().title,
            subjectId: tempQ.data().subjectId,
          }
        })
        const pDate = action.payload.params.date
        const today = new Date(pDate)
        const date = new Date(today);
        const firstDate = new Date((new Date(date.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)))).setHours(0, 0, 0, 0))
        const lastDat = new Date((new Date(today.setDate(today.getDate() - today.getDay() + 7))).setHours(23, 59, 59, 0))
        const firstDayOfTheWeek = new Date(firstDate)
        let weekArray: Array<string> = []
        for (let i = 0; i < 7; i++) {
          weekArray.push(firstDayOfTheWeek.toDateString())
          firstDayOfTheWeek.setDate(firstDayOfTheWeek.getDate() + 1)
        }
        const classDays = query(collection(db, "ClassDay"), where("date", ">", firstDate), where("date", "<", lastDat))
        const classDaysData = await getDocs(classDays)
        let totalClassDays: any = [];
        classDaysData.forEach((querySnapshot: any) => {
          if (arr[querySnapshot.data().classId]) {
            totalClassDays.push(querySnapshot.data())
          }
        })
        let finalData: any = [];
        let sameDateClasses: any = [];
        let week: any = {};
        const check = totalClassDays.sort((a: any, b: any) => a.date.toDate() - b.date.toDate())
        let currentCheckDate = check[0]?.date?.toDate()?.toDateString()
        for (let i = 0; i < check.length; i++) {
          // console.log(check[i].date.toDate().toDateString())
          if (check[i].date.toDate().toDateString() === currentCheckDate) {
            sameDateClasses.push({ ...check[i], ...arr[check[i].classId] })
          } else {
            week[currentCheckDate] = true
            finalData.push({ [currentCheckDate]: sameDateClasses })
            currentCheckDate = check[i].date.toDate().toDateString();
            sameDateClasses = [{ ...check[i], ...arr[check[i].classId] }]
          }
        }
        if (sameDateClasses.length > 0) {
          week[currentCheckDate] = true
          finalData.push({ [currentCheckDate]: sameDateClasses })
        }
        for (let i = 0; i < weekArray.length; i++) {
          if (week[weekArray[i]] === undefined) {
            finalData.splice(i, 0, { [weekArray[i]]: [] })
          }
        }
        return finalData;
      } else {
        return "";
      }
    }

    const data = yield call(get);
    if (data) {
      yield put(getWeekClassDaysForAdminSuccess(data));
    } else {
      yield put(getWeekClassDaysForAdminFailure("No Classes found"));
    }
  } catch (err: any) {
    console.log(err)
    yield put(getWeekClassDaysForAdminFailure("Something went wrong"));
  }
}

export function* getWeekClassDaysForAdminSaga() {
  yield takeLatest(GET_WEEK_CLASS_DAYS_FOR_ADMIN_INIT, getWeekClassDaysForAdmin);
}

function* getSubjects(action: any): any {
  try {
    const get = async () => {
      const subjects = collection(db, "Subjects")
      const subjectData = await getDocs(subjects);

      let arr: any = []
      subjectData.forEach((querySnapshot) => {
        arr.push(querySnapshot.data())
      })

      if (arr.length > 0) {
        return arr
      } else {
        return "";
      }
    }

    const data = yield call(get);
    if (data) {
      yield put(getSubjectsSuccess(data));
    } else {
      yield put(getSubjectsFailure("No Subjects found"));
    }
  } catch (err: any) {
    console.log(err)
    yield put(getSubjectsFailure("Something went wrong"));
  }
}

export function* getSubjectsSaga() {
  yield takeLatest(GET_SUBJECTS_INIT, getSubjects);
}

function* getSubjectMentor(action: any): any {
  try {
    const get = async () => {
      const teacher = query(collection(db, "MentorProfile"), where("subjectIds", "array-contains", action.payload.params.subjectId))
      const teacherData = await getDocs(teacher);

      let arr: any = []
      teacherData.forEach((querySnapshot) => {
        arr.push(querySnapshot.data())
      })

      if (arr.length > 0) {
        return arr
      } else {
        return "";
      }
    }

    const data = yield call(get);
    if (data) {
      yield put(getSubjectMentorSuccess(data));
    } else {
      yield put(getSubjectMentorFailure("No Mentors found"));
    }
  } catch (err: any) {
    console.log(err)
    yield put(getSubjectMentorFailure("Something went wrong"));
  }
}

export function* getSubjectMentorSaga() {
  yield takeLatest(GET_SUBJECT_MENTOR_INIT, getSubjectMentor);
}

function* getMentor(action: any): any {
  try {
    const get = async () => {
      const mentorRef = doc(db, "MentorProfile", action.payload.params.mentorId)
      const mentorGet = await getDoc(mentorRef)
      const mentorData = mentorGet.data()
      if (!mentorData) {
        return false
      }
      return mentorData
    }

    const data = yield call(get);
    if (data) {
      yield put(getMentorSuccess(data));
    } else {
      yield put(getMentorFailure("No Mentors found"));
    }
  } catch (err: any) {
    console.log(err)
    yield put(getMentorFailure("Something went wrong"));
  }
}

export function* getMentorSaga() {
  yield takeLatest(GET_MENTOR_INIT, getMentor);
}

function* addClassDay(action: any): any {
  try {
    const add = async () => {
      const { date, time, mentorId, ...rest } = action.payload.params
      const updatedDate = action.payload.params.date;
      const updatedTime = action.payload.params.time
      updatedDate.setHours(updatedTime.slice(0, 2), updatedTime.slice(3, 5), "00")
      const docRef = doc(collection(db, "ClassDay"));
      const classRoomRef = doc(db, "Classroom", action.payload.params.classId)
      const classRoomRefData = await getDoc(classRoomRef)
      const classRoomData = classRoomRefData.data()?.mentorIds || []
      await updateDoc(classRoomRef, {
        mentorIds: [... new Set([...classRoomData, mentorId])]
      })
      await setDoc(docRef, { ...rest, date: updatedDate, classDayId: docRef.id, mentorId: mentorId })
      return true
    }

    const data = yield call(add);
    if (data) {
      yield put(addClassDaySuccess(data));
    } else {
      yield put(addClassDayFailure("No Classroom found with that Id"));
    }
  } catch (err: any) {
    console.log(err)
    yield put(addClassDayFailure("Something went wrong"));
  }
}

export function* addClassDaySaga() {
  yield takeLatest(ADD_CLASS_DAY_INIT, addClassDay);
}

function* updateClassDay(action: any): any {
  try {
    const update = async () => {
      const date = action.payload.params.date;
      const time = action.payload.params.time
      date.setHours(time.slice(0, 2), time.slice(3, 5), "00")
      const docRef = doc(db, "ClassDay", action.payload.params.classDayId);
      if (action.payload.params.mentorId) {
        const classRoomRef = doc(db, "Classroom", action.payload.params.classId)
        const classRoomRefData = await getDoc(classRoomRef)
        const classRoomData = classRoomRefData.data()?.mentorIds || []
        await updateDoc(classRoomRef, {
          mentorIds: [... new Set([...classRoomData, action.payload.params.mentorId])]
        })
        await updateDoc(docRef, {
          date: date,
          classId: action.payload.params.classId,
          mentorId: action.payload.params.mentorId
        })
      } else {
        await updateDoc(docRef, {
          topic: action.payload.params.topic,
          date: date
        })
      }
      return true;
    }

    const data = yield call(update);
    if (data) {
      yield put(updateClassDaySuccess(data));
    } else {
      yield put(updateClassDayFailure("Could not update class day"));
    }
  } catch (err: any) {
    console.log(err)
    yield put(updateClassDayFailure("Something went wrong"));
  }
}

export function* updateClassDaySaga() {
  yield takeLatest(UPDATE_CLASS_DAY_INIT, updateClassDay);
}

function* getClassDay(action: any): any {
  try {
    const classDay = async () => {
      const docRef = doc(db, "ClassDay", action.payload.params.classDayId);
      const response = await getDoc(docRef);
      if (!response) {
        return {
          error: true,
          message: "Something went wrong",
        };
      }
      return response;
    }
    const data = yield call(classDay);
    if (!data.error) {
      yield put(getClassDaySuccess(data.data()));
    } else {
      yield put(getClassDayFailure(data.message));
    }
  } catch (err: any) {
    console.log(err)
    yield put(getClassDayFailure(err.message));
  }
}

export function* getClassDaySaga() {
  yield takeLatest(GET_CLASS_DAY_INIT, getClassDay);
}

function* addUserTimestamp(action: any): any {
  try {
    const updateUserTimeStamp = async () => {
      try {
        let docRef = doc(db, "ClassAnalytics", action.payload.params.classDayId);
        let classAnalyticsGet = await getDoc(docRef)
        let classAnalyticsData = classAnalyticsGet.data()
        const date = new Date()
        if (!classAnalyticsData) {
          await setDoc(docRef, {
            [action.payload.params.userId]: {
              startTime: date,
              time: [],
              endTime: date
            }
          })
          docRef = doc(db, "ClassAnalytics", action.payload.params.classDayId)
          classAnalyticsGet = await getDoc(docRef)
          classAnalyticsData = classAnalyticsGet.data()
        }

        const { userId } = action.payload.params
        try {
          const userAnalyticsData: any = classAnalyticsData?.[userId] ? classAnalyticsData?.[userId] : {
            time: [],
            endTime: date
          }
          await updateDoc(docRef, {
            [userId]: {
              ...userAnalyticsData,
              startTime: date
            }
          })
        } catch (e: any) {
          console.log(e)
          return false
        }
      } catch (error: any) {
        console.log(error)
        return false
      }
    }
    const data = yield call(updateUserTimeStamp);
    if (data) {
      yield put(addUserTimestampSuccess({}));
    } else {
      yield put(addUserTimestampFailure("Something went wrong"));
    }
  } catch (err: any) {
    console.log(err)
    yield put(addUserTimestampFailure("Something went wrong"));
  }
}

export function* addUserTimestampSaga() {
  yield takeLatest(ADD_USER_TIMESTAMP_INIT, addUserTimestamp);
}

function* addUserLeaveTimestamp(action: any): any {
  try {
    const updateUserLeaveTimeStamp = async () => {
      try {
        let docRef = doc(db, "ClassAnalytics", action.payload.params.classDayId);
        let classAnalyticsGet = await getDoc(docRef)
        let classAnalyticsData = classAnalyticsGet.data()

        const { userId } = action.payload.params
        try {
          const userAnalyticsData = classAnalyticsData?.[userId] ? classAnalyticsData?.[userId] : { time: [] }
          await setDoc(docRef, {
            ...classAnalyticsData,
            [userId]: {
              ...userAnalyticsData,
              endTime: new Date(),
              time: [
                ...userAnalyticsData.time,
                ((new Date()).getTime() - userAnalyticsData.startTime.toDate().getTime()) / 1000
              ]
            }
          })
        } catch (e: any) {
          console.log(e)
          return false
        }
      } catch (error: any) {
        return false
      }
    }
    const data = yield call(updateUserLeaveTimeStamp);
    if (data) {
      yield put(addUserLeaveTimestampSuccess({}));
    } else {
      yield put(addUserLeaveTimestampFailure("Something went wrong"));
    }
  } catch (err: any) {
    console.log(err)
    yield put(addUserLeaveTimestampFailure("Something went wrong"));
  }
}

export function* addUserLeaveTimestampSaga() {
  yield takeLatest(ADD_USER_LEAVE_TIMESTAMP_INIT, addUserLeaveTimestamp);
}

function* deleteClassDay(action: any): any {
  try {
    const deleteClassD = async () => {
      const docRef = doc(db, "ClassDay", action.payload.params.classDayId);
      try {
        await deleteDoc(docRef)
        return true
      } catch (error: any) {
        return false
      }
    }
    const data = yield call(deleteClassD);
    if (data) {
      yield put(deleteClassDaySuccess({}));
    } else {
      yield put(deleteClassDayFailure("Something went wrong"));
    }
  } catch (err: any) {
    console.log(err)
    yield put(deleteClassDayFailure("Something went wrong"));
  }
}

export function* deleteClassDaySaga() {
  yield takeLatest(DELETE_CLASS_DAY_INIT, deleteClassDay);
}

function* creditCoinsForStudents(action: any): any {
  try {
    const headers = {
      'Content-Type': 'application/json',
    }
    const presentUsers = action.payload.params.presentUsers;
    const url = `https://us-central1-tsoappnew.cloudfunctions.net/creditCoinsToStudents?classDayId=${action.payload.params.classDayId}&className=${action.payload.params.className}&classId=${action.payload.params.classId}`;
    const data = yield call(postRequest, url, {}, headers);
    if (!data.error) {
      yield put(creditCoinsForStudentsSuccess(data));
    } else {
      yield put(creditCoinsForStudentsFailure(data));
    }
  } catch (err: any) {
    yield put(creditCoinsForStudentsFailure(err.message));
  }
}

export function* creditCoinsForStudentsSaga() {
  yield takeLatest(CREDIT_COINS_FOR_STUDENTS_INIT, creditCoinsForStudents);
}

function* uploadRecordingToDrive(action: any): any {
  try {
    const headers = {
      'Content-Type': 'application/json',
    }
    const url = `https://script.google.com/macros/s/AKfycbymqZ4cWEJGNilTC51FWVWz5MJ7iDpM0kl6iWqK2QAdGC13N6kVENe1hqgJk6WSwlcY5Q/exec`;
    const data = yield call(postRequest, url, JSON.stringify(action.payload.params.data), headers);
    if (!data.error) {
      yield put(uploadRecordingToDriveSuccess(data));
    } else {
      yield put(uploadRecordingToDriveFailure(data));
    }
  } catch (err: any) {
    yield put(uploadRecordingToDriveFailure(err.message));
  }
}

export function* uploadRecordingToDriveSaga() {
  yield takeLatest(UPLOAD_RECORDING_TO_DRIVE_INIT, uploadRecordingToDrive);
}

function* addMeetingIdToFirebase(action: any): any {
  try {
    const add = async () => {
      try {
        const classDayRef = doc(db, "ClassDay", action.payload.params.classDayId)
        const classDayGet = await getDoc(classDayRef)
        const classDayData = classDayGet.data()
        if (!classDayData) {
          return false
        } else {
          await updateDoc(classDayRef, {
            meetingIds: [...classDayData?.meetingIds || "", action.payload.params.meetingId]
          })
          return true
        }
      } catch (e: any) {
        console.log(e)
        return false
      }
    }
    const data = yield call(add);
    if (data) {
      yield put(addMeetingIdToFirebaseSuccess(data));
    } else {
      yield put(addMeetingIdToFirebaseFailure("Something went wrong"));
    }
  } catch (err: any) {
    yield put(addMeetingIdToFirebaseFailure(err.message));
  }
}

export function* addMeetingIdToFirebaseSaga() {
  yield takeLatest(ADD_MEETING_ID_TO_FIREBASE_INIT, addMeetingIdToFirebase);
}

function* addDocumentsToClassDay(action: any): any {
  try {
    const add = async () => {
      try {
        const classDayRef = doc(db, "ClassDay", action.payload.params.classDayId)
        const classDayGet = await getDoc(classDayRef)
        const classDayData = classDayGet.data()
        if (!classDayData) {
          return false
        } else {
          await updateDoc(classDayRef, {
            documents: action.payload.params.data
          })
          return true
        }
      } catch (e: any) {
        console.log(e)
        return false
      }
    }
    const data = yield call(add);
    if (data) {
      yield put(addDocumentsToClassDaySuccess(data));
    } else {
      yield put(addDocumentsToClassDayFailure("Something went wrong"));
    }
  } catch (err: any) {
    yield put(addDocumentsToClassDayFailure(err.message));
  }
}

export function* addDocumentsToClassDaySaga() {
  yield takeLatest(ADD_DOCUMENTS_TO_CLASS_DAY_INIT, addDocumentsToClassDay);
}
