import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
const LoggedInRoute = ({ children }: any) => {

  const { user } = useUserAuth();

  if (user) {
    return <Navigate to="/" />;
  }

  return children;
};
export default LoggedInRoute;