import {
  GET_CLASS_DAYS_FOR_TEACHER_INIT,
  GET_CLASS_DAYS_FOR_TEACHER_SUCCESS,
  GET_CLASS_DAYS_FOR_TEACHER_FAILURE,
  GET_CLASS_DAYS_FOR_STUDENT_INIT,
  GET_CLASS_DAYS_FOR_STUDENT_SUCCESS,
  GET_CLASS_DAYS_FOR_STUDENT_FAILURE,
  ADD_HLS_LINK_TO_FIREBASE_INIT,
  ADD_HLS_LINK_TO_FIREBASE_SUCCESS,
  ADD_HLS_LINK_TO_FIREBASE_FAILURE,
  REMOVE_HLS_LINK_FROM_FIREBASE_INIT,
  REMOVE_HLS_LINK_FROM_FIREBASE_SUCCESS,
  REMOVE_HLS_LINK_FROM_FIREBASE_FAILURE,
  GET_HLS_LINK_FOR_CLASS_INIT,
  GET_HLS_LINK_FOR_CLASS_SUCCESS,
  GET_HLS_LINK_FOR_CLASS_FAILURE,
  JOIN_HLS_INIT,
  LEAVE_HLS_INIT,
  GET_CMS_DATA_INIT,
  GET_CMS_DATA_SUCCESS,
  GET_CMS_DATA_FAILURE,
  SEND_PUSH_NOTIFICATION_INIT,
  SEND_PUSH_NOTIFICATION_SUCCESS,
  SEND_PUSH_NOTIFICATION_FAILURE,
  SEND_PUSH_NOTIFICATION_TO_TOPIC_INIT,
  SEND_PUSH_NOTIFICATION_TO_TOPIC_SUCCESS,
  SEND_PUSH_NOTIFICATION_TO_TOPIC_FAILURE,
  GET_CLASS_WITH_CLASS_ID_INIT,
  GET_CLASS_WITH_CLASS_ID_SUCCESS,
  GET_CLASS_WITH_CLASS_ID_FAILURE,
  RESET_CLASS_WITH_CLASS_ID,
  GET_WEEK_CLASS_DAYS_FOR_TEACHER_INIT,
  GET_WEEK_CLASS_DAYS_FOR_TEACHER_SUCCESS,
  GET_WEEK_CLASS_DAYS_FOR_TEACHER_FAILURE,
  GET_SUBJECTS_INIT,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAILURE,
  GET_SUBJECT_MENTOR_INIT,
  GET_SUBJECT_MENTOR_SUCCESS,
  GET_SUBJECT_MENTOR_FAILURE,
  GET_MENTOR_INIT,
  GET_MENTOR_SUCCESS,
  GET_MENTOR_FAILURE,
  ADD_CLASS_DAY_INIT,
  ADD_CLASS_DAY_SUCCESS,
  ADD_CLASS_DAY_FAILURE,
  UPDATE_CLASS_DAY_INIT,
  UPDATE_CLASS_DAY_SUCCESS,
  UPDATE_CLASS_DAY_FAILURE,
  RESET_CLASS_DAY_RESPONSES,
  GET_WEEK_CLASS_DAYS_FOR_ADMIN_INIT,
  GET_WEEK_CLASS_DAYS_FOR_ADMIN_SUCCESS,
  GET_WEEK_CLASS_DAYS_FOR_ADMIN_FAILURE,
  GET_CLASS_DAY_INIT,
  GET_CLASS_DAY_SUCCESS,
  GET_CLASS_DAY_FAILURE,
  ADD_USER_TIMESTAMP_INIT,
  ADD_USER_TIMESTAMP_SUCCESS,
  ADD_USER_TIMESTAMP_FAILURE,
  ADD_USER_LEAVE_TIMESTAMP_INIT,
  ADD_USER_LEAVE_TIMESTAMP_SUCCESS,
  ADD_USER_LEAVE_TIMESTAMP_FAILURE,
  DELETE_CLASS_DAY_INIT,
  DELETE_CLASS_DAY_SUCCESS,
  DELETE_CLASS_DAY_FAILURE,
  CREDIT_COINS_FOR_STUDENTS_INIT,
  CREDIT_COINS_FOR_STUDENTS_SUCCESS,
  CREDIT_COINS_FOR_STUDENTS_FAILURE,
  UPLOAD_RECORDING_TO_DRIVE_INIT,
  UPLOAD_RECORDING_TO_DRIVE_SUCCESS,
  UPLOAD_RECORDING_TO_DRIVE_FAILURE,
  ADD_MEETING_ID_TO_FIREBASE_INIT,
  ADD_MEETING_ID_TO_FIREBASE_SUCCESS,
  ADD_MEETING_ID_TO_FIREBASE_FAILURE,
  ADD_DOCUMENTS_TO_CLASS_DAY_INIT,
  ADD_DOCUMENTS_TO_CLASS_DAY_SUCCESS,
  ADD_DOCUMENTS_TO_CLASS_DAY_FAILURE,
  RESET_HLS_LINK_STATUS
} from "../actionTypes";

export const getClassDaysForTeacherSuccess = (data: any) => {
  return {
    type: GET_CLASS_DAYS_FOR_TEACHER_SUCCESS,
    data
  }
}

export const getClassDaysForTeacherFailure = (error: any) => {
  return {
    type: GET_CLASS_DAYS_FOR_TEACHER_FAILURE,
    error
  }
}

export const getClassDaysForTeacher = (params: any) => {
  const payload = { params };
  return {
    type: GET_CLASS_DAYS_FOR_TEACHER_INIT,
    payload,
  }
}

export const getClassDaysForStudentSuccess = (data: any) => {
  return {
    type: GET_CLASS_DAYS_FOR_STUDENT_SUCCESS,
    data
  }
}

export const getClassDaysForStudentFailure = (error: any) => {
  return {
    type: GET_CLASS_DAYS_FOR_STUDENT_FAILURE,
    error
  }
}

export const getClassDaysForStudent = (params: any) => {
  const payload = { params };
  return {
    type: GET_CLASS_DAYS_FOR_STUDENT_INIT,
    payload,
  }
}

export const addHlsLinkToFirebaseSuccess = (data: any) => {
  return {
    type: ADD_HLS_LINK_TO_FIREBASE_SUCCESS,
    data
  }
}

export const addHlsLinkToFirebaseFailure = (error: any) => {
  return {
    type: ADD_HLS_LINK_TO_FIREBASE_FAILURE,
    error
  }
}

export const addHlsLinkToFirebase = (params: any) => {
  const payload = { params };
  return {
    type: ADD_HLS_LINK_TO_FIREBASE_INIT,
    payload,
  }
}

export const removeHlsLinkFromFirebaseSuccess = (data: any) => {
  return {
    type: REMOVE_HLS_LINK_FROM_FIREBASE_SUCCESS,
    data
  }
}

export const removeHlsLinkFromFirebaseFailure = (error: any) => {
  return {
    type: REMOVE_HLS_LINK_FROM_FIREBASE_FAILURE,
    error
  }
}

export const removeHlsLinkFromFirebase = (params: any) => {
  const payload = { params };
  return {
    type: REMOVE_HLS_LINK_FROM_FIREBASE_INIT,
    payload,
  }
}

export const getHlsLinkForClassSuccess = (data: any) => {
  return {
    type: GET_HLS_LINK_FOR_CLASS_SUCCESS,
    data
  }
}

export const getHlsLinkForClassFailure = (error: any) => {
  return {
    type: GET_HLS_LINK_FOR_CLASS_FAILURE,
    error
  }
}

export const getHlsLinkForClass = (params: any) => {
  const payload = { params };
  return {
    type: GET_HLS_LINK_FOR_CLASS_INIT,
    payload,
  }
}

export const getCmsDataSuccess = (data: any) => {
  return {
    type: GET_CMS_DATA_SUCCESS,
    data
  }
}

export const getCmsDataFailure = (error: any) => {
  return {
    type: GET_CMS_DATA_FAILURE,
    error
  }
}

export const getCmsData = (params: any) => {
  const payload = { params };
  return {
    type: GET_CMS_DATA_INIT,
    payload,
  }
}

export const joinHls = (data: any) => {
  return {
    type: JOIN_HLS_INIT,
    data
  }
}

export const leaveHls = () => {
  return {
    type: LEAVE_HLS_INIT,
  }
}

export const sendPushNotificationSuccess = (data: any) => {
  return {
    type: SEND_PUSH_NOTIFICATION_SUCCESS,
    data
  }
}

export const sendPushNotificationFailure = (error: any) => {
  return {
    type: SEND_PUSH_NOTIFICATION_FAILURE,
    error
  }
}

export const sendPushNotification = (params: any) => {
  const payload = { params };
  return {
    type: SEND_PUSH_NOTIFICATION_INIT,
    payload,
  }
}

export const sendPushNotificationToTopicSuccess = (data: any) => {
  return {
    type: SEND_PUSH_NOTIFICATION_TO_TOPIC_SUCCESS,
    data
  }
}

export const sendPushNotificationToTopicFailure = (error: any) => {
  return {
    type: SEND_PUSH_NOTIFICATION_TO_TOPIC_FAILURE,
    error
  }
}

export const sendPushNotificationToTopic = (params: any) => {
  const payload = { params };
  return {
    type: SEND_PUSH_NOTIFICATION_TO_TOPIC_INIT,
    payload,
  }
}

export const getClassWithClassIdSuccess = (data: any) => {
  return {
    type: GET_CLASS_WITH_CLASS_ID_SUCCESS,
    data
  }
}

export const getClassWithClassIdFailure = (error: any) => {
  return {
    type: GET_CLASS_WITH_CLASS_ID_FAILURE,
    error
  }
}

export const resetClassWithClassId = () => {
  return {
    type: RESET_CLASS_WITH_CLASS_ID
  }
}

export const getClassWithClassId = (params: any) => {
  const payload = { params };
  return {
    type: GET_CLASS_WITH_CLASS_ID_INIT,
    payload,
  }
}

export const getWeekClassDaysForTeacherSuccess = (data: any) => {
  return {
    type: GET_WEEK_CLASS_DAYS_FOR_TEACHER_SUCCESS,
    data
  }
}

export const getWeekClassDaysForTeacherFailure = (error: any) => {
  return {
    type: GET_WEEK_CLASS_DAYS_FOR_TEACHER_FAILURE,
    error
  }
}

export const getWeekClassDaysForTeacher = (params: any) => {
  const payload = { params };
  return {
    type: GET_WEEK_CLASS_DAYS_FOR_TEACHER_INIT,
    payload,
  }
}

export const getWeekClassDaysForAdminSuccess = (data: any) => {
  return {
    type: GET_WEEK_CLASS_DAYS_FOR_ADMIN_SUCCESS,
    data
  }
}

export const getWeekClassDaysForAdminFailure = (error: any) => {
  return {
    type: GET_WEEK_CLASS_DAYS_FOR_ADMIN_FAILURE,
    error
  }
}

export const getWeekClassDaysForAdmin = (params: any) => {
  const payload = { params };
  return {
    type: GET_WEEK_CLASS_DAYS_FOR_ADMIN_INIT,
    payload,
  }
}

export const getSubjectsSuccess = (data: any) => {
  return {
    type: GET_SUBJECTS_SUCCESS,
    data
  }
}

export const getSubjectsFailure = (error: any) => {
  return {
    type: GET_SUBJECTS_FAILURE,
    error
  }
}

export const getSubjects = (params: any) => {
  const payload = { params };
  return {
    type: GET_SUBJECTS_INIT,
    payload,
  }
}

export const getSubjectMentorSuccess = (data: any) => {
  return {
    type: GET_SUBJECT_MENTOR_SUCCESS,
    data
  }
}

export const getSubjectMentorFailure = (error: any) => {
  return {
    type: GET_SUBJECT_MENTOR_FAILURE,
    error
  }
}

export const getSubjectMentor = (params: any) => {
  const payload = { params };
  return {
    type: GET_SUBJECT_MENTOR_INIT,
    payload,
  }
}

export const getMentorSuccess = (data: any) => {
  return {
    type: GET_MENTOR_SUCCESS,
    data
  }
}

export const getMentorFailure = (error: any) => {
  return {
    type: GET_MENTOR_FAILURE,
    error
  }
}

export const getMentor = (params: any) => {
  const payload = { params };
  return {
    type: GET_MENTOR_INIT,
    payload,
  }
}

export const addClassDaySuccess = (data: any) => {
  return {
    type: ADD_CLASS_DAY_SUCCESS,
    data
  }
}

export const addClassDayFailure = (error: any) => {
  return {
    type: ADD_CLASS_DAY_FAILURE,
    error
  }
}

export const addClassDay = (params: any) => {
  const payload = { params };
  return {
    type: ADD_CLASS_DAY_INIT,
    payload,
  }
}

export const updateClassDaySuccess = (data: any) => {
  return {
    type: UPDATE_CLASS_DAY_SUCCESS,
    data
  }
}

export const updateClassDayFailure = (error: any) => {
  return {
    type: UPDATE_CLASS_DAY_FAILURE,
    error
  }
}

export const updateClassDay = (params: any) => {
  const payload = { params };
  return {
    type: UPDATE_CLASS_DAY_INIT,
    payload,
  }
}

export const getClassDaySuccess = (data: any) => {
  return {
    type: GET_CLASS_DAY_SUCCESS,
    data
  }
}

export const getClassDayFailure = (error: any) => {
  return {
    type: GET_CLASS_DAY_FAILURE,
    error
  }
}

export const getClassDay = (params: any) => {
  const payload = { params };
  return {
    type: GET_CLASS_DAY_INIT,
    payload,
  }
}

export const resetClassDayResponses = () => {
  return {
    type: RESET_CLASS_DAY_RESPONSES
  }
}

export const addUserTimestampSuccess = (data: any) => {
  return {
    type: ADD_USER_TIMESTAMP_SUCCESS,
    data
  }
}

export const addUserTimestampFailure = (error: any) => {
  return {
    type: ADD_USER_TIMESTAMP_FAILURE,
    error
  }
}

export const addUserTimestamp = (params: any) => {
  const payload = { params };
  return {
    type: ADD_USER_TIMESTAMP_INIT,
    payload,
  }
}

export const addUserLeaveTimestampSuccess = (data: any) => {
  return {
    type: ADD_USER_LEAVE_TIMESTAMP_SUCCESS,
    data
  }
}

export const addUserLeaveTimestampFailure = (error: any) => {
  return {
    type: ADD_USER_LEAVE_TIMESTAMP_FAILURE,
    error
  }
}

export const addUserLeaveTimestamp = (params: any) => {
  const payload = { params };
  return {
    type: ADD_USER_LEAVE_TIMESTAMP_INIT,
    payload,
  }
}

export const deleteClassDaySuccess = (data: any) => {
  return {
    type: DELETE_CLASS_DAY_SUCCESS,
    data
  }
}

export const deleteClassDayFailure = (error: any) => {
  return {
    type: DELETE_CLASS_DAY_FAILURE,
    error
  }
}

export const deleteClassDay = (params: any) => {
  const payload = { params };
  return {
    type: DELETE_CLASS_DAY_INIT,
    payload,
  }
}

export const creditCoinsForStudentsSuccess = (data: any) => {
  return {
    type: CREDIT_COINS_FOR_STUDENTS_SUCCESS,
    data
  }
}

export const creditCoinsForStudentsFailure = (error: any) => {
  return {
    type: CREDIT_COINS_FOR_STUDENTS_FAILURE,
    error
  }
}

export const creditCoinsForStudents = (params: any) => {
  const payload = { params };
  return {
    type: CREDIT_COINS_FOR_STUDENTS_INIT,
    payload,
  }
}

export const uploadRecordingToDriveSuccess = (data: any) => {
  return {
    type: UPLOAD_RECORDING_TO_DRIVE_SUCCESS,
    data
  }
}

export const uploadRecordingToDriveFailure = (error: any) => {
  return {
    type: UPLOAD_RECORDING_TO_DRIVE_FAILURE,
    error
  }
}

export const uploadRecordingToDrive = (params: any) => {
  const payload = { params };
  return {
    type: UPLOAD_RECORDING_TO_DRIVE_INIT,
    payload,
  }
}

export const addMeetingIdToFirebaseSuccess = (data: any) => {
  return {
    type: ADD_MEETING_ID_TO_FIREBASE_SUCCESS,
    data
  }
}

export const addMeetingIdToFirebaseFailure = (error: any) => {
  return {
    type: ADD_MEETING_ID_TO_FIREBASE_FAILURE,
    error
  }
}

export const addMeetingIdToFirebase = (params: any) => {
  const payload = { params };
  return {
    type: ADD_MEETING_ID_TO_FIREBASE_INIT,
    payload,
  }
}

export const addDocumentsToClassDaySuccess = (data: any) => {
  return {
    type: ADD_DOCUMENTS_TO_CLASS_DAY_SUCCESS,
    data
  }
}

export const addDocumentsToClassDayFailure = (error: any) => {
  return {
    type: ADD_DOCUMENTS_TO_CLASS_DAY_FAILURE,
    error
  }
}

export const addDocumentsToClassDay = (params: any) => {
  const payload = { params };
  return {
    type: ADD_DOCUMENTS_TO_CLASS_DAY_INIT,
    payload,
  }
}

export const resetHlsLinkStatus = (params: any) => {
  const payload = { params };
  return {
    type: RESET_HLS_LINK_STATUS,
    payload,
  }
}