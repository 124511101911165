import {
  GET_OTP_INIT,
  GET_OTP_SUCCESS,
  GET_OTP_FAILURE,
  VERIFY_OTP_INIT,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  FIREBASE_SIGNED_OUT,
  GET_USER_INIT,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
} from "../actionTypes";

export const getOTPSuccess = (data: any) => {
  return {
    type: GET_OTP_SUCCESS,
    data
  }
}

export const getOTPFailure = (error: any) => {
  return {
    type: GET_OTP_FAILURE,
    error
  }
}

export const getOTP = (params: any) => {
  const payload = { params };
  return {
    type: GET_OTP_INIT,
    payload,
  }
}

export const verifyOTPSuccess = (data: any) => {
  return {
    type: VERIFY_OTP_SUCCESS,
    data
  }
}

export const verifyOTPFailure = (error: any) => {
  return {
    type: VERIFY_OTP_FAILURE,
    error
  }
}

export const verifyOTP = (params: any) => {
  const payload = { params };
  return {
    type: VERIFY_OTP_INIT,
    payload,
  }
}

export const firebaseSignedOut = () => {
  return {
    type: FIREBASE_SIGNED_OUT,
  }
}

export const getUserSuccess = (data: any) => {
  return {
    type: GET_USER_SUCCESS,
    data
  }
}

export const getUserFailure = (error: any) => {
  return {
    type: GET_USER_FAILURE,
    error
  }
}

export const getUser = (params: any) => {
  const payload = { params };
  return {
    type: GET_USER_INIT,
    payload,
  }
}