import { Box, Button, Modal } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addClassDay, getSubjectMentor, resetClassDayResponses, updateClassDay } from "../../redux/classes/classesActions";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "10px",
    boxShadow: 0,
    pt: 2,
    px: 4,
    pb: 3,
};

function ChildModal() {
    const [open, setOpen] = useState<boolean>(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button onClick={handleOpen}>Open Child Modal</Button>
            <Modal
                hideBackdrop
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <div className="bg-white bg-opacity-70 w-fit absolute top-[50%] left-[50%]">
                    H
                    <Button onClick={handleClose}>Close Child Modal</Button>
                </div>
            </Modal>
        </>
    );
}

const AdminClassModal = ({
    modalOpen,
    setModalOpen,
    modalContent,
    setModalContent
}: any) => {
    const [time, setTime] = useState<any>((new Date()).toTimeString().slice(0, 5));
    const [subject, setSubject] = useState<string>("");
    const [teacher, setTeacher] = useState<string>("");

    useEffect(() => {
        if (modalContent?.date) {
            setTime(modalContent?.date?.toDate().toTimeString()?.slice(0, 5))
            setSubject(modalContent?.subjectId)
            setTeacher(modalContent?.mentorId)
        }
    }, [modalContent])
    const dispatch = useDispatch();

    const {
        isUpdatingClassDay,
        updateClassDayResp,
        updateClassDayRespStatus,
        updateClassDayError,
        updateClassDayErrorMsg,
        isAddingClassDay,
        addClassDayResp,
        addClassDayRespStatus,
        addClassDayError,
        addClassDayErrorMsg,
        isGettingSubject,
        getSubjectsResp,
        isGettingSubjectMentor,
        getSubjectMentorResp,
        getSubjectMentorRespStatus,
        getSubjectMentorError,
        getSubjectMentorErrorMsg
    } = useSelector((state: any) => state.classes)


    useEffect(() => {
        if (updateClassDayRespStatus === 200) {
            setTeacher("")
            setSubject("")
            setTime((new Date()).toTimeString().slice(0, 5))
            setModalContent({})
            setModalOpen(false)
            dispatch(resetClassDayResponses());
        } else if (updateClassDayError) {
            alert(updateClassDayErrorMsg)
        }
    }, [isUpdatingClassDay]);

    useEffect(() => {
        if (addClassDayRespStatus === 200) {
            setTeacher("")
            setSubject("")
            setTime((new Date()).toTimeString().slice(0, 5))
            setModalContent({})
            setModalOpen(false)
            dispatch(resetClassDayResponses());
        } else if (addClassDayError) {
            alert(addClassDayErrorMsg)
        }
    }, [isAddingClassDay]);

    useEffect(() => {
        if (getSubjectMentorError) {
            setTeacher("")
            alert(getSubjectMentorErrorMsg)
        }
    }, [isGettingSubjectMentor]);

    const handleSave = (e: any) => {
        e.preventDefault();
        if (modalContent?.date) {
            dispatch(updateClassDay({
                classDayId: modalContent?.classDayId,
                classId: modalContent?.classId,
                date: modalContent.date?.toDate(),
                time: time,
                mentorId: teacher,
                subjectId: subject
            }))
        } else {
            dispatch(addClassDay({
                date: new Date(modalContent.containerDate),
                time: time,
                classId: subject === "sub001" ? "utLpmfXn8hWvuSitjPkq" : subject === "sub002" ? "DdoSPhyd664THkQqV1nZ" : subject === "sub003" ? "BizAS3v3TepLLlTd2XVa" : "APmSFmMbJJwMueyUCbLB",
                isCompleted: false,
                isLive: false,
                studentReviews: [],
                teacherReview: {},
                timeTracker: {},
                topic: "",
                mentorId: teacher
            }))
        }
    }

    return (
        <Modal
            open={modalOpen}
            onClose={() => {
                setTeacher("")
                setSubject("")
                setTime((new Date()).toTimeString().slice(0, 5))
                setModalContent({})
                setModalOpen(false)
            }}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            {/* <Box sx={{ ...style, width: 400 }}>
        <h2 id="parent-modal-title">Text in a modal</h2>
        <p id="parent-modal-description">
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </p>
        <ChildModal />
      </Box> */}
            <div className="bg-white bg-opacity-70 rounded-[10px] w-fit absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] p-5">
                <div className="text-center text-xl mb-3">
                    {modalContent?.date === undefined ? "ADD" : "EDIT"} Class
                </div>
                <div>
                    {`${modalContent.containerDate}`}
                </div>
                <form onSubmit={handleSave}>
                    <div className="flex flex-col gap-3 mt-2">
                        <input type="time" value={time} className="border-none outline-none bg-[#F6F6F6] px-3 py-1 rounded-[10px]" placeholder="Enter Topic" required onChange={(e: any) => setTime(e.target.value)} />
                        <select
                            value={subject}
                            className="border-none outline-none bg-[#F6F6F6] px-3 py-1 rounded-[10px]"
                            onChange={(e: any) => {
                                dispatch(getSubjectMentor({
                                    subjectId: e.target.value
                                }))
                                setSubject(e.target.value)
                            }}
                            disabled={isGettingSubject || isGettingSubjectMentor}
                            required
                        >
                            <option value="">Select subject</option>
                            {getSubjectsResp.map((e: any) => {
                                return (
                                    <option value={e.subjectId}>{e.subjectName}</option>
                                )
                            })}
                        </select>
                        <select
                            value={teacher}
                            className="border-none outline-none bg-[#F6F6F6] px-3 py-1 rounded-[10px]"
                            onChange={(e: any) => { setTeacher(e.target.value) }}
                            disabled={isGettingSubject || isGettingSubjectMentor || getSubjectMentorRespStatus !== 200}
                            required
                        >
                            <option value="">Select mentor</option>
                            {getSubjectMentorResp.map((e: any) => {
                                return (
                                    <option value={e.mentorId}>{e.mentorName}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="flex w-100 justify-center">
                        <button type="submit" className="border-none outline-none bg-white text-black px-5 py-2 rounded mt-5" onClick={handleSave}>
                            {isUpdatingClassDay || isAddingClassDay ? <div className="spinner-border animate-spin inline-block w-8 h-8 border-whtie-4 rounded-full" role="status">
                            </div> : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default AdminClassModal;