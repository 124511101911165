import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { db } from "../firebaseConfig";
import { joinHls } from "../redux/classes/classesActions";

const ClassListItem = ({
  day,
  handleJoinMeetingWithId,
  isTeacher,
}: any) => {

  const [isLiveAvailable, setIsLiveAvailable] = useState<boolean>(false);
  const [currentItemData, setCurrentItemData] = useState<any>([]);
  const dispatch = useDispatch();

  const handleJoinLive = () => {
    dispatch(joinHls({ hlsLink: currentItemData["hlsLink"], classId: currentItemData.classId, classDayId: day[Object.keys(day)[0]]?.classDayId, hasPaid: day.hasPaid, mentorId: day[Object.keys(day)[0]]?.mentorId }));
  }

  const unsub = onSnapshot(doc(db, "Classroom", day["classId"]), (doc) => {
    const data: any = doc.data();
    if (data && data["isLive"]) {
      setIsLiveAvailable(true);
      setCurrentItemData(data);
    } else {
      setIsLiveAvailable(false);
    }
  });

  useEffect(() => {
    return () => {
      unsub();
    };
  }, []);

  const timeFormatOptions = {
    hour: "2-digit", minute: "2-digit"
  }
  const originalDate = day[Object.keys(day)[0]].date.toDate();
  const beforeOneHourDate = new Date(originalDate.getTime() - 60 * 60 * 1000);
  const afterOneHourDate = new Date(originalDate.getTime() + 60 * 60 * 1000);

  const bufferAvailable = new Date() > beforeOneHourDate && new Date() < afterOneHourDate;
  return (
    <div className={"text-sm md:text-lg grid grid-cols-4 gap-4 px-3 py-3 rounded-lg mb-2" + (bufferAvailable && " bg-blue-400")} key={day["meetingId"]}>
      <div className={"col-span-2" + (bufferAvailable ? " font-bold" : "")}>
        {Object.keys(day)[0]} Class
      </div>
      <div>
        {day[Object.keys(day)[0]].date.toDate().toLocaleTimeString("en-in", timeFormatOptions)}
      </div>
      {((bufferAvailable)) && <div className="bg-blue-300 text-center flex justify-center items-center rounded-lg cursor-pointer" onClick={() => {
        handleJoinMeetingWithId({ role: isTeacher ? "teacher" : "hls-viewer", classId: day.classId, classDayId: day[Object.keys(day)[0]].classDayId, roomId: day.meetingId, hasPaid: (day.hasPaid || isTeacher), className: Object.keys(day)[0] })
      }}>
        Join
      </div>}
      {/* {isLiveAvailable && isTeacher === false && <div className="bg-blue-300 text-center flex justify-center items-center rounded-lg cursor-pointer" onClick={() => {
        setCurrentlyClickedClassId(day.classId)
        setCurrentlyClickedClassDayId(day[Object.keys(day)[0]].classDayId)
        handleJoinLive()
      }}>
        Join
      </div>} */}
    </div>
  )
}

export default ClassListItem;