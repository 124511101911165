import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import logo from "../../assets/png/snipe.png";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { firebaseSignedOut } from "../../redux/auth/authActions";
import useWindowSize from "../../utils/useWindowSize";
import { FileUploader } from "react-drag-drop-files";
import { Modal } from "@material-ui/core";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebaseConfig";


const Navbar = () => {
  const uploadFile = async (data: any) => {
    setPercent(0)
    setDownloadLink("")
    setCopied(false)
    const type = data.type.split("/")[0]
    const format = data.type.split("/")[1]
    let imageRef: any = {};
    if (type === "image") {
      imageRef = ref(storage, `AdminData/Images/${data.name}`)
    } else if (type === "audio") {
      imageRef = ref(storage, `AdminData/Audio/${data.name}`)
    } else if (format === "pdf") {
      imageRef = ref(storage, `AdminData/Pdf/${data.name}`)
    } else {
      imageRef = ref(storage, `AdminData/Others/${data.name}`)
    }
    const uploadTask = uploadBytesResumable(imageRef, data);
    setShowPercent(true)
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setPercent(progress)
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        switch (error.code) {
          case 'storage/unauthorized':
            console.log("unauthorized")
            break;
          case 'storage/canceled':
            console.log("canceled")
            break;
          case 'storage/unknown':
            console.log("unknown")
            break;
        }
      },
      () => {
        setShowPercent(false)
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setDownloadLink(downloadURL)
        });
      }
    );
  }
  const timeOptions: any = {
    hour: "numeric",
    minute: "numeric"
  }

  const dateOptions: any = {
    weekday: "short",
    month: "short",
    day: "numeric"
  }
  const [time, setTime] = useState(new Date().toLocaleTimeString("en-IN", timeOptions))
  const [date, setDate] = useState(new Date().toLocaleDateString("en-IN", dateOptions))
  const [openSettings, setOpenSettings] = useState<boolean>(false);

  const admins = ["9409122983", "1234543210", "9330232990", "6398212209", "9163599295"]


  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);

  const [percent, setPercent] = useState<number>(0);
  const [showPercent, setShowPercent] = useState<boolean>(false);
  const [downloadLink, setDownloadLink] = useState<string>("");
  const [copied, setCopied] = useState<boolean>(false);

  const { user, logOut, userDetails } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (copied) {
      const copy = setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [copied]);

  useEffect(() => {
    setInterval(() => {
      setTime(new Date().toLocaleTimeString("en-IN", timeOptions))
      setDate(new Date().toLocaleDateString("en-IN", dateOptions))
    }, 1000);
  }, []);
  const { width } = useWindowSize();

  return (
    <div className="h-[100px] text-white text-sm md:text-xl">
      <div className="flex justify-between items-center h-full px-10">
        <div>
          <a href="https://snipeit.club" target="_blank">
            <img src={logo} alt="logo" className="w-[120px] md:w-[200px] cursor-pointer" />
          </a>
        </div>
        <div className="flex items-center gap-2 md:gap-5">
          {width > 768 && <>{user && <div className={"cursor-pointer" + (location.pathname === "/" ? " border-white border-b-2" : "")} onClick={() => navigate("/")}>
            Home
          </div>}
            {user && userDetails?.userType === 2 && <div className={"cursor-pointer" + (location.pathname === "/schedule" ? " border-white border-b-2" : "")} onClick={() => navigate("/schedule")}>
              Schedule
            </div>}
            {user && admins.includes(userDetails?.phone) && <div className={"cursor-pointer" + (location.pathname === "/admin" ? " border-white border-b-2" : "")} onClick={() => navigate("/admin")}>
              Admin
            </div>}</>}
          {`${time} | ${date}`}
          {user && <>
            <FontAwesomeIcon icon={faGear} className="cursor-pointer relative" onClick={() => {
              setOpenSettings(!openSettings);
            }} />
            {openSettings && <div className="flex flex-col absolute bg-white rounded text-black px-5 py-1 text-xs md:text-sm custor-pointer right-[45px] mt-[130px]">
              {width <= 768 && <><div className={"cursor-pointer mb-2"} onClick={() => navigate("/")}>
                Home
              </div>
                {userDetails?.userType === 2 && <div className={"cursor-pointer mb-2"} onClick={() => navigate("/schedule")}>
                  Schedule
                </div>}
                {admins.includes(userDetails?.phone) && <div className={"cursor-pointer mb-2"} onClick={() => navigate("/admin")}>
                  Admin
                </div>}</>}
              {admins.includes(userDetails?.phone) && <div className="cursor-pointer mb-2" onClick={() => setModalOpen(true)}>
                Upload File
              </div>}
              <div className="cursor-pointer" onClick={async () => {
                await logOut();
                dispatch(firebaseSignedOut());
                navigate("/login");

              }}>
                Logout
              </div>
            </div>}
          </>}
        </div>
      </div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setDownloadLink("")
          setShowPercent(false)
          setPercent(0)
          setModalOpen(false)
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        {/* <Box sx={{ ...style, width: 400 }}>
        <h2 id="parent-modal-title">Text in a modal</h2>
        <p id="parent-modal-description">
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </p>
        <ChildModal />
      </Box> */}
        <div className="bg-white rounded-[10px] w-fit absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] p-5">
          <FileUploader
            handleChange={uploadFile}
            // onDrop={uploadFile}
            name="file" />
          {showPercent && <div className="text-center">
            {percent}% upload completed
          </div>}
          {downloadLink && <div className="flex justify-center mt-5"><div className="text-center px-3 py-1 border border-[black] rounded w-fit cursor-pointer" onClick={() => {
            navigator.clipboard.writeText(downloadLink)
            setCopied(true)
          }}>
            {copied ? <div>Copied</div> : <div>Copy to clipboard</div>}
          </div></div>}
        </div>
      </Modal>
    </div>
  )
}

export default Navbar;