import Button from 'react-bootstrap/Button';
import { FC, useEffect, useState } from 'react';
import OtpInput from '../../components/auth/otpInput';
import { useDispatch, useSelector } from 'react-redux';
import { getOTP, verifyOTP } from '../../redux/auth/authActions';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import Navbar from '../../components/navbar/navbar';

const AuthIndex: FC = () => {

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [otpSent, setOTPSent] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>('');
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isGettingOTP,
    getOTPError,
    getOTPErrorMsg,
    getOTPResp,
    getOTPRespStatus,
    isVerifyingOTP,
    verifyOTPError,
    // verifyOTPErrorMsg,
    verifyOTPResp,
    verifyOTPRespStatus,
  } = useSelector((state: any) => {
    return {
      isGettingOTP: state.auth.isGettingOTP,
      getOTPError: state.auth.getOTPError,
      getOTPErrorMsg: state.auth.getOTPErrorMsg,
      getOTPResp: state.auth.getOTPResp,
      getOTPRespStatus: state.auth.getOTPRespStatus,
      isVerifyingOTP: state.auth.isVerifyingOTP,
      verifyOTPError: state.auth.verifyOTPError,
      // verifyOTPErrorMsg: state.auth.verifyOTPErrorMsg,
      verifyOTPResp: state.auth.verifyOTPResp,
      verifyOTPRespStatus: state.auth.verifyOTPRespStatus,
    };
  });

  const onChange = (value: string) => setOtp(value);

  // GET OTP
  const handleGetOTP = () => {
    if (phoneNumber.length === 10) {
      const getOTPData = {
        "phone": phoneNumber,
        "isSignup": false,
        "name": "",
        "isAdmin": false
      }
      dispatch(getOTP(getOTPData));
    } else {
      alert('Please enter a valid phone number');
    }
  }

  useEffect(() => {
    if (getOTPRespStatus === 200) {
      setOTPSent(true);
      setOtp("");
    } else {
      if (getOTPError) {
        alert(getOTPErrorMsg);
      }
      setOTPSent(false);
    }
  }, [isGettingOTP]);


  // VERIFY OTP
  const handleVerifyOTP = () => {
    if (otp.length === 5) {
      const verifyOTPData = {
        "otp": otp,
        "otphash": getOTPResp.otphash,
        "uid": getOTPResp.uid,
      }
      dispatch(verifyOTP(verifyOTPData));
    } else {
      alert('Please enter a valid OTP');
      setOTPSent(false);
    }
  }

  const { logIn } = useUserAuth();

  useEffect(() => {
    if (verifyOTPRespStatus === 200) {
      (async function () {
        setIsLoggingIn(true);
        await logIn(verifyOTPResp.token);
        setIsLoggingIn(false);
        setOTPSent(false);
        setOtp('');
        navigate('/');
      })();
    } else {
      if (verifyOTPError) {
        alert('Invalid OTP');
        setOTPSent(false);
      }
    }
  }, [verifyOTPRespStatus]);

  return (
    <div className="custom-bg">
      <Navbar />
      <div className="h-[calc(100vh-100px)] flex justify-center items-center">
        <div className="auth-content mt-[-100px]">
          <div className='text-white text-5xl mb-[50px] text-center'>
            Snipe Classes
          </div>
          {otpSent ?
            <div className="text-center">
              <div id="otp" className="inputs d-flex flex-row justify-content-center">
                <OtpInput value={otp} valueLength={5} onChange={onChange} />
              </div>
            </div>
            :
            <div className='text-lg font-bold'>
              <div className='col-12 col-md-8 col-lg-6'>
                <input
                  type="number"
                  maxLength={10}
                  placeholder="Enter Your Phone Number"
                  className="px-4 py-2 rounded-lg w-full"
                  value={phoneNumber}
                  disabled={isGettingOTP}
                  onChange={(e) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                      setPhoneNumber(e.target.value);
                    } else {
                      setPhoneNumber(e.target.value);
                    }
                  }}
                />
              </div>
            </div>
          }

          {otpSent ?
            <div className='mt-5 flex justify-center'>
              <Button disabled={isVerifyingOTP || isLoggingIn} className="bg-red-150 text-white px-5 py-2 rounded" onClick={() => {
                handleVerifyOTP();
              }} style={{ backgroundColor: "#D32F2F" }}>
                {(isVerifyingOTP || isLoggingIn) ? <div className="spinner-border animate-spin inline-block w-8 h-8 border-whtie-4 rounded-full" role="status">
                </div> : "Verify OTP"}
              </Button>{' '}
            </div>
            :
            <div className='mt-5 flex justify-center'>
              <Button disabled={isGettingOTP} className="bg-red-150 text-white px-5 py-2 rounded" onClick={() => {
                handleGetOTP();
              }} style={{ backgroundColor: "#D32F2F" }}>
                {isGettingOTP ? <div className="spinner-border animate-spin inline-block w-8 h-8 border-whtie-4 rounded-full" role="status">
                </div> : "Get OTP"}
              </Button>{' '}
            </div>
          }

          {otpSent && !(isVerifyingOTP || isLoggingIn) ?
            <div className='text-center'>
              <div className='text-white mt-5'>
                OTP Sent!
              </div>
              <div className='text-white'>
                Didn’t receive the OTP! Click <u className='cursor-pointer' onClick={handleGetOTP}>here</u> to resend it!
              </div>
            </div>
            :
            null
          }
        </div>
      </div>
    </div>
  );
}

export default AuthIndex;