import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import instagramPost1 from "../../assets/png/instagram-post-1.jpg";
import instagramPost2 from "../../assets/png/instagram-post-2.jpg";
import instagramReel from "../../assets/png/snipe-video.mp4";
import "./newLatest.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCmsData } from "../../redux/classes/classesActions";
import axios from "axios";

const NewLatest = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCmsData({}));
  }, []);

  const {
    isGettingCmsData,
    getCmsDataError,
    getCmsDataErrorMsg,
    getCmsDataResp,
    getCmsDataRespStatus
  } = useSelector((state: any) => state.classes);

  useEffect(() => {
    if (getCmsDataRespStatus === 200) {
    } else if (getCmsDataError) {
      alert("Error retrieving latest posts");
    }
  }, [isGettingCmsData]);

  return (
    <>
      {isGettingCmsData ? <div>
        Loading
      </div> : <div className="grid grid-cols-7 gap-3 text-white text-[12px] md:text-[16px]">
        <div className="flex flex-col-reverse md:flex-col justify-between col-span-7 md:col-span-5 lg:col-span-4">
          <div className="my-5 md:mt-0">
            <div className="youtube-container">
              <iframe src={`https://www.youtube.com/embed/${getCmsDataResp?.youtube?.url?.split(".be/")[1]}` || "https://www.youtube.com/embed/Fn4ut5NhjzM"} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            <div className="flex justify-between mt-2 gap-5">
              <h2 className="fw-bold text-[16px] md:text-[22px]">
                {getCmsDataResp?.youtube?.title}
              </h2>
              <div className="w-50 text-[12px] md:text-[14px]">
                {getCmsDataResp?.youtube?.description}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:mb-5">
            <div>
              <div className="instagram-post-container relative">
                <img src={getCmsDataResp?.instagram?.[0]?.url || instagramPost1} alt="instagram post 1" className="w-full h-full" />
                <div className="latest-instagram-icon absolute text-white">
                  <FontAwesomeIcon icon={faInstagram} />
                </div>
              </div>
              <div className="my-2">
                <h2 className="fw-bold my-3 text-[16px] md:text-[22px]">
                  {getCmsDataResp?.instagram?.[0]?.title}
                </h2>
                <div className="w-50 text-[12px] md:text-[14px]">
                  {getCmsDataResp?.instagram?.[0]?.description}
                </div>
              </div>
            </div>
            <div>
              <div className="instagram-post-container relative">
                <img src={getCmsDataResp?.instagram?.[1]?.url || instagramPost2} alt="instagram post 2" className="w-full h-full" />
                <div className="latest-instagram-icon absolute text-white">
                  <FontAwesomeIcon icon={faInstagram} />
                </div>
              </div>
              <div className="my-2">
                <h2 className="fw-bold my-2 text-[16px] md:text-[22px]">
                  {getCmsDataResp?.instagram?.[1]?.title}
                </h2>
                <div className="w-50 text-[12px] md:text-[14px]">
                  {getCmsDataResp?.instagram?.[1]?.description}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}

export default NewLatest;