import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
const ProtectedRoute = ({ children }: any) => {
  const { user, userDetails } = useUserAuth();
  const admins = ["9409122983", "1234543210", "9330232990", "6398212209", "9163599295"]
  const location = useLocation()

  if (user) {
    if (userDetails?.name === undefined) {
      return (
        <div className="h-[100vh] flex justify-center items-center text-white">
          Fetching details
        </div>
      )
    }
    if ((location.pathname === "/schedule") && userDetails?.userType === 3) {
      return (
        <Navigate to="/" />
      )
    }

    if (location.pathname === "/admin" && !admins.includes(userDetails?.phone)) {
      return (
        <Navigate to="/" />
      )
    }

  }

  if (!user && userDetails?.email === undefined) {
    return <Navigate to="/login" />;
  }

  return children;
};
export default ProtectedRoute;