import {
  GET_OTP_INIT,
  GET_OTP_SUCCESS,
  GET_OTP_FAILURE,
  VERIFY_OTP_INIT,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  FIREBASE_SIGNED_OUT,
  GET_USER_INIT,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
} from '../actionTypes';
import { IAuthState } from '../../models/auth/auth';

const initialState = {
  isGettingOTP: false,
  getOTPError: false,
  getOTPErrorMsg: "",
  getOTPResp: {},
  getOTPRespStatus: 0,
  isVerifyingOTP: false,
  verifyOTPError: false,
  verifyOTPErrorMsg: "",
  verifyOTPResp: {},
  verifyOTPRespStatus: 0,
  isGettingUser: false,
  getUserError: false,
  getUserErrorMsg: "",
  getUserResp: {},
  getUserRespStatus: 0,
}

const auth = (state: IAuthState = initialState, action: any) => {
  switch (action.type) {

    case GET_OTP_INIT:
      return {
        ...state,
        isGettingOTP: true,
        getOTPError: false,
        getOTPRespStatus: 0
      };

    case GET_OTP_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingOTP: false,
        getOTPResp: data,
        getOTPRespStatus: 200,
      };
    }

    case GET_OTP_FAILURE:
      const { error } = action;
      return {
        ...state,
        isGettingOTP: false,
        getOTPError: true,
        getOTPErrorMsg: error.message,
        getOTPRespStatus: error.status,
      };

    case VERIFY_OTP_INIT:
      return {
        ...state,
        isVerifyingOTP: true,
        verifyOTPError: false,
        verifyOTPRespStatus: 0
      };

    case VERIFY_OTP_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isVerifyingOTP: false,
        verifyOTPResp: data,
        verifyOTPRespStatus: 200,
      };
    }

    case VERIFY_OTP_FAILURE:
      return {
        ...state,
        isVerifyingOTP: false,
        verifyOTPError: true,
        verifyOTPErrorMsg: action.error,
        verifyOTPRespStatus: action.error.status,
      };

    case FIREBASE_SIGNED_OUT:
      return {
        ...state,
        verifyOTPRespStatus: 0,
        getOTPRespStatus: 0,
      }

    case GET_USER_INIT:
      return {
        ...state,
        isGettingUser: true,
        getUserError: false,
        getUserRespStatus: 0
      };

    case GET_USER_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingUser: false,
        getUserResp: data,
        getUserRespStatus: 200,
      };
    }

    case GET_USER_FAILURE:
      return {
        ...state,
        isGettingUser: false,
        getUserError: true,
        getUserErrorMsg: action.error,
        getUserRespStatus: action.error.status,
      };

    default:
      return state;
  }
};

export default auth;
