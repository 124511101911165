import {
  GET_CLASS_DAYS_FOR_TEACHER_INIT,
  GET_CLASS_DAYS_FOR_TEACHER_SUCCESS,
  GET_CLASS_DAYS_FOR_TEACHER_FAILURE,
  GET_CLASS_DAYS_FOR_STUDENT_INIT,
  GET_CLASS_DAYS_FOR_STUDENT_SUCCESS,
  GET_CLASS_DAYS_FOR_STUDENT_FAILURE,
  ADD_HLS_LINK_TO_FIREBASE_INIT,
  ADD_HLS_LINK_TO_FIREBASE_SUCCESS,
  ADD_HLS_LINK_TO_FIREBASE_FAILURE,
  REMOVE_HLS_LINK_FROM_FIREBASE_INIT,
  REMOVE_HLS_LINK_FROM_FIREBASE_SUCCESS,
  REMOVE_HLS_LINK_FROM_FIREBASE_FAILURE,
  JOIN_HLS_INIT,
  LEAVE_HLS_INIT,
  GET_HLS_LINK_FOR_CLASS_INIT,
  GET_HLS_LINK_FOR_CLASS_SUCCESS,
  GET_HLS_LINK_FOR_CLASS_FAILURE,
  GET_CMS_DATA_INIT,
  GET_CMS_DATA_SUCCESS,
  GET_CMS_DATA_FAILURE,
  SEND_PUSH_NOTIFICATION_INIT,
  SEND_PUSH_NOTIFICATION_SUCCESS,
  SEND_PUSH_NOTIFICATION_FAILURE,
  SEND_PUSH_NOTIFICATION_TO_TOPIC_INIT,
  SEND_PUSH_NOTIFICATION_TO_TOPIC_SUCCESS,
  SEND_PUSH_NOTIFICATION_TO_TOPIC_FAILURE,
  GET_CLASS_WITH_CLASS_ID_INIT,
  GET_CLASS_WITH_CLASS_ID_SUCCESS,
  GET_CLASS_WITH_CLASS_ID_FAILURE,
  RESET_CLASS_WITH_CLASS_ID,
  GET_WEEK_CLASS_DAYS_FOR_TEACHER_INIT,
  GET_WEEK_CLASS_DAYS_FOR_TEACHER_SUCCESS,
  GET_WEEK_CLASS_DAYS_FOR_TEACHER_FAILURE,
  GET_WEEK_CLASS_DAYS_FOR_ADMIN_INIT,
  GET_WEEK_CLASS_DAYS_FOR_ADMIN_SUCCESS,
  GET_WEEK_CLASS_DAYS_FOR_ADMIN_FAILURE,
  GET_SUBJECTS_INIT,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAILURE,
  GET_SUBJECT_MENTOR_INIT,
  GET_SUBJECT_MENTOR_SUCCESS,
  GET_SUBJECT_MENTOR_FAILURE,
  GET_MENTOR_INIT,
  GET_MENTOR_SUCCESS,
  GET_MENTOR_FAILURE,
  ADD_CLASS_DAY_INIT,
  ADD_CLASS_DAY_SUCCESS,
  ADD_CLASS_DAY_FAILURE,
  UPDATE_CLASS_DAY_INIT,
  UPDATE_CLASS_DAY_SUCCESS,
  UPDATE_CLASS_DAY_FAILURE,
  RESET_CLASS_DAY_RESPONSES,
  GET_CLASS_DAY_INIT,
  GET_CLASS_DAY_SUCCESS,
  GET_CLASS_DAY_FAILURE,
  ADD_USER_TIMESTAMP_INIT,
  ADD_USER_TIMESTAMP_SUCCESS,
  ADD_USER_TIMESTAMP_FAILURE,
  ADD_USER_LEAVE_TIMESTAMP_INIT,
  ADD_USER_LEAVE_TIMESTAMP_SUCCESS,
  ADD_USER_LEAVE_TIMESTAMP_FAILURE,
  DELETE_CLASS_DAY_INIT,
  DELETE_CLASS_DAY_SUCCESS,
  DELETE_CLASS_DAY_FAILURE,
  CREDIT_COINS_FOR_STUDENTS_INIT,
  CREDIT_COINS_FOR_STUDENTS_SUCCESS,
  CREDIT_COINS_FOR_STUDENTS_FAILURE,
  UPLOAD_RECORDING_TO_DRIVE_INIT,
  UPLOAD_RECORDING_TO_DRIVE_SUCCESS,
  UPLOAD_RECORDING_TO_DRIVE_FAILURE,
  ADD_MEETING_ID_TO_FIREBASE_INIT,
  ADD_MEETING_ID_TO_FIREBASE_SUCCESS,
  ADD_MEETING_ID_TO_FIREBASE_FAILURE,
  ADD_DOCUMENTS_TO_CLASS_DAY_INIT,
  ADD_DOCUMENTS_TO_CLASS_DAY_SUCCESS,
  ADD_DOCUMENTS_TO_CLASS_DAY_FAILURE,
  RESET_HLS_LINK_STATUS
} from '../actionTypes';
import { IClassesState } from '../../models/classes/classes';

const initialState = {
  isGettingClassDaysForTeacher: false,
  getClassDaysForTeacherError: false,
  getClassDaysForTeacherErrorMsg: '',
  getClassDaysForTeacherRespStatus: 0,
  getClassDaysForTeacherResp: [],
  isGettingClassDaysForStudent: false,
  getClassDaysForStudentError: false,
  getClassDaysForStudentErrorMsg: '',
  getClassDaysForStudentRespStatus: 0,
  getClassDaysForStudentResp: [],
  isAddingHlsLinkToFirebase: false,
  addHlsLinkToFirebaseError: false,
  addHlsLinkToFirebaseErrorMsg: '',
  addHlsLinkToFirebaseResp: {},
  addHlsLinkToFirebaseRespStatus: 0,
  isRemovingHlsLinkFromFirebase: false,
  removeHlsLinkFromFirebaseError: false,
  removeHlsLinkFromFirebaseErrorMsg: '',
  removeHlsLinkFromFirebaseResp: {},
  removeHlsLinkFromFirebaseRespStatus: 0,
  joinHls: false,
  hlsLink: "",
  hlsClassId: "",
  hlsClassDayId: "",
  hlsHasPaid: false,
  hlsMentorId: "",
  isGettingHlsLinkForClass: false,
  getHlsLinkForClassError: false,
  getHlsLinkForClassErrorMsg: '',
  getHlsLinkForClassResp: {},
  getHlsLinkForClassRespStatus: 0,
  isGettingCmsData: false,
  getCmsDataError: false,
  getCmsDataErrorMsg: '',
  getCmsDataResp: {},
  getCmsDataRespStatus: 0,
  isSendingPushNotification: false,
  sendPushNotificationResp: [],
  sendPushNotificationRespStatus: 0,
  sendPushNotificationError: false,
  sendPushNotificationErrorMsg: "",
  isSendingPushNotificationToTopic: false,
  sendPushNotificationToTopicResp: [],
  sendPushNotificationToTopicRespStatus: 0,
  sendPushNotificationToTopicError: false,
  sendPushNotificationToTopicErrorMsg: "",
  isGettingClassWithClassId: false,
  getClassWithClassIdError: false,
  getClassWithClassIdErrorMsg: "",
  getClassWithClassIdResp: [],
  getClassWithClassIdRespStatus: 0,
  isGettingWeekClassDaysForTeacher: false,
  getWeekClassDaysForTeacherResp: [],
  getWeekClassDaysForTeacherRespStatus: 0,
  getWeekClassDaysForTeacherError: false,
  getWeekClassDaysForTeacherErrorMsg: "",
  isGettingWeekClassDaysForAdmin: false,
  getWeekClassDaysForAdminResp: [],
  getWeekClassDaysForAdminRespStatus: 0,
  getWeekClassDaysForAdminError: false,
  getWeekClassDaysForAdminErrorMsg: "",
  isGettingSubjects: false,
  getSubjectsResp: [],
  getSubjectsRespStatus: 0,
  getSubjectsError: false,
  getSubjectsErrorMsg: "",
  isGettingSubjectMentor: false,
  getSubjectMentorResp: [],
  getSubjectMentorRespStatus: 0,
  getSubjectMentorError: false,
  getSubjectMentorErrorMsg: "",
  isGettingMentor: false,
  getMentorResp: {},
  getMentorRespStatus: 0,
  getMentorError: false,
  getMentorErrorMsg: "",
  isAddingClassDay: false,
  addClassDayResp: [],
  addClassDayRespStatus: 0,
  addClassDayError: false,
  addClassDayErrorMsg: "",
  isUpdatingClassDay: false,
  updateClassDayResp: [],
  updateClassDayRespStatus: 0,
  updateClassDayError: false,
  updateClassDayErrorMsg: "",
  isGettingClassDay: false,
  getClassDayResp: [],
  getClassDayRespStatus: 0,
  getClassDayError: false,
  getClassDayErrorMsg: "",
  isAddingUserTimestamp: false,
  addUserTimestampResp: [],
  addUserTimestampRespStatus: 0,
  addUserTimestampError: false,
  addUserTimestampErrorMsg: "",
  isAddingUserLeaveTimestamp: false,
  addUserLeaveTimestampResp: [],
  addUserLeaveTimestampRespStatus: 0,
  addUserLeaveTimestampError: false,
  addUserLeaveTimestampErrorMsg: "",
  isDeletingClassDay: false,
  deleteClassDayResp: [],
  deleteClassDayRespStatus: 0,
  deleteClassDayError: false,
  deleteClassDayErrorMsg: "",
  isCreditingCoinsForStudents: false,
  creditCoinsForStudentsResp: [],
  creditCoinsForStudentsRespStatus: 0,
  creditCoinsForStudentsError: false,
  creditCoinsForStudentsErrorMsg: "",
  isUploadingRecordingToDrive: false,
  uploadRecordingToDriveResp: {},
  uploadRecordingToDriveRespStatus: 0,
  uploadRecordingToDriveError: false,
  uploadRecordingToDriveErrorMsg: "",
  isAddingMeetingIdToFirebase: false,
  addMeetingIdToFirebaseResp: {},
  addMeetingIdToFirebaseRespStatus: 0,
  addMeetingIdToFirebaseError: false,
  addMeetingIdToFirebaseErrorMsg: "",
  isAddingDocumentsToClassDay: false,
  addDocumentsToClassDayResp: [],
  addDocumentsToClassDayRespStatus: 0,
  addDocumentsToClassDayError: false,
  addDocumentsToClassDayErrorMsg: "",

}

const classes = (state: IClassesState = initialState, action: any) => {
  switch (action.type) {

    case GET_CLASS_DAYS_FOR_TEACHER_INIT:
      return {
        ...state,
        isGettingClassDaysForTeacher: true,
        getClassDaysForTeacherError: false,
        getClassDaysForTeacherRespStatus: 0,
        getClassDaysForTeacherResp: [],
      }

    case GET_CLASS_DAYS_FOR_TEACHER_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingClassDaysForTeacher: false,
        getClassDaysForTeacherError: false,
        getClassDaysForTeacherRespStatus: 200,
        getClassDaysForTeacherResp: data,
      }
    }

    case GET_CLASS_DAYS_FOR_TEACHER_FAILURE:
      return {
        ...state,
        isGettingClassDaysForTeacher: false,
        getClassDaysForTeacherError: true,
        getClassDaysForTeacherRespStatus: 400,
        getClassDaysForTeacherErrorMsg: action.error,
      }

    case GET_CLASS_DAYS_FOR_STUDENT_INIT:
      return {
        ...state,
        isGettingClassDaysForStudent: true,
        getClassDaysForStudentError: false,
        getClassDaysForStudentRespStatus: 0,
        getClassDaysForStudentResp: {},
      }

    case GET_CLASS_DAYS_FOR_STUDENT_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingClassDaysForStudent: false,
        getClassDaysForStudentError: false,
        getClassDaysForStudentRespStatus: 200,
        getClassDaysForStudentResp: data,
      }
    }

    case GET_CLASS_DAYS_FOR_STUDENT_FAILURE:
      return {
        ...state,
        isGettingClassDaysForStudent: false,
        getClassDaysForStudentError: true,
        getClassDaysForStudentRespStatus: 400,
        getClassDaysForStudentErrorMsg: action.error,
      }

    case ADD_HLS_LINK_TO_FIREBASE_INIT:
      return {
        ...state,
        isAddingHlsLinkToFirebase: true,
        addHlsLinkToFirebaseError: false,
        addHlsLinkToFirebaseRespStatus: 0,
        addHlsLinkToFirebaseResp: {},
      }

    case ADD_HLS_LINK_TO_FIREBASE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingHlsLinkToFirebase: false,
        addHlsLinkToFirebaseError: false,
        addHlsLinkToFirebaseRespStatus: 200,
        addHlsLinkToFirebaseResp: data,
      }
    }

    case ADD_HLS_LINK_TO_FIREBASE_FAILURE:
      return {
        ...state,
        isAddingHlsLinkToFirebase: false,
        addHlsLinkToFirebaseError: true,
        addHlsLinkToFirebaseRespStatus: 400,
        addHlsLinkToFirebaseErrorMsg: action.error,
      }

    case REMOVE_HLS_LINK_FROM_FIREBASE_INIT:
      return {
        ...state,
        isRemovingHlsLinkFromFirebase: true,
        removeHlsLinkFromFirebaseError: false,
        removeHlsLinkFromFirebaseRespStatus: 0,
        removeHlsLinkFromFirebaseResp: {},
      }

    case REMOVE_HLS_LINK_FROM_FIREBASE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isRemovingHlsLinkFromFirebase: false,
        removeHlsLinkFromFirebaseError: false,
        removeHlsLinkFromFirebaseRespStatus: 200,
        removeHlsLinkFromFirebaseResp: data,
      }
    }

    case REMOVE_HLS_LINK_FROM_FIREBASE_FAILURE:
      return {
        ...state,
        isRemovingHlsLinkFromFirebase: false,
        removeHlsLinkFromFirebaseError: true,
        removeHlsLinkFromFirebaseRespStatus: 400,
        removeHlsLinkFromFirebaseErrorMsg: action.error,
      }

    case JOIN_HLS_INIT: {
      return {
        ...state,
        joinHls: true,
        hlsLink: action.data.hlsLink,
        hlsClassId: action.data.classId,
        hlsClassDayId: action.data.classDayId,
        hlsHasPaid: action.data.hasPaid,
        hlsMentorId: action.data.mentorId
      }
    }

    case LEAVE_HLS_INIT: {
      return {
        ...state,
        joinHls: false,
        hlsLink: '',
        hlsClassId: '',
        hlsClassDayId: '',
        hlsHasPaid: false,
        hlsMentorId: ""
      }
    }

    case GET_HLS_LINK_FOR_CLASS_INIT:
      return {
        ...state,
        isGettingHlsLinkForClass: true,
        getHlsLinkForClassError: false,
        getHlsLinkForClassRespStatus: 0,
        getHlsLinkForClassResp: {},
      }

    case GET_HLS_LINK_FOR_CLASS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingHlsLinkForClass: false,
        getHlsLinkForClassError: false,
        getHlsLinkForClassRespStatus: 200,
        getHlsLinkForClassResp: data,
      }
    }

    case GET_HLS_LINK_FOR_CLASS_FAILURE:
      return {
        ...state,
        isGettingHlsLinkForClass: false,
        getHlsLinkForClassError: true,
        getHlsLinkForClassRespStatus: 400,
        getHlsLinkForClassErrorMsg: action.error,
      }

    case GET_CMS_DATA_INIT:
      return {
        ...state,
        isGettingCmsData: true,
        getCmsDataError: false,
        getCmsDataRespStatus: 0,
        getCmsDataResp: {},
      }

    case GET_CMS_DATA_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingCmsData: false,
        getCmsDataError: false,
        getCmsDataRespStatus: 200,
        getCmsDataResp: data,
      }
    }

    case GET_CMS_DATA_FAILURE:
      return {
        ...state,
        isGettingCmsData: false,
        getCmsDataError: true,
        getCmsDataRespStatus: 400,
        getCmsDataErrorMsg: action.error,
      }

    case SEND_PUSH_NOTIFICATION_INIT:
      return {
        ...state,
        isSendingPushNotification: true,
        sendPushNotificationError: false,
        sendPushNotificationRespStatus: 0,
        sendPushNotificationResp: {},
      }

    case SEND_PUSH_NOTIFICATION_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isSendingPushNotification: false,
        sendPushNotificationError: false,
        sendPushNotificationRespStatus: 200,
        sendPushNotificationResp: data,
      }
    }

    case SEND_PUSH_NOTIFICATION_FAILURE:
      return {
        ...state,
        isSendingPushNotification: false,
        sendPushNotificationError: true,
        sendPushNotificationRespStatus: 400,
        sendPushNotificationErrorMsg: action.error,
      }

    case SEND_PUSH_NOTIFICATION_TO_TOPIC_INIT:
      return {
        ...state,
        isSendingPushNotificationToTopic: true,
        sendPushNotificationToTopicError: false,
        sendPushNotificationToTopicRespStatus: 0,
        sendPushNotificationToTopicResp: {},
      }

    case SEND_PUSH_NOTIFICATION_TO_TOPIC_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isSendingPushNotificationToTopic: false,
        sendPushNotificationToTopicError: false,
        sendPushNotificationToTopicRespStatus: 200,
        sendPushNotificationToTopicResp: data,
      }
    }

    case SEND_PUSH_NOTIFICATION_TO_TOPIC_FAILURE:
      return {
        ...state,
        isSendingPushNotificationToTopic: false,
        sendPushNotificationToTopicError: true,
        sendPushNotificationToTopicRespStatus: 400,
        sendPushNotificationToTopicErrorMsg: action.error,
      }

    case GET_CLASS_WITH_CLASS_ID_INIT:
      return {
        ...state,
        isGettingClassWithClassId: true,
        getClassWithClassIdError: false,
        getClassWithClassIdRespStatus: 0,
        getClassWithClassIdResp: {},
      }

    case GET_CLASS_WITH_CLASS_ID_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingClassWithClassId: false,
        getClassWithClassIdError: false,
        getClassWithClassIdRespStatus: 200,
        getClassWithClassIdResp: data,
      }
    }

    case GET_CLASS_WITH_CLASS_ID_FAILURE:
      return {
        ...state,
        isGettingClassWithClassId: false,
        getClassWithClassIdError: true,
        getClassWithClassIdRespStatus: 400,
        getClassWithClassIdErrorMsg: action.error,
      }

    case RESET_CLASS_WITH_CLASS_ID:
      return {
        ...state,
        getClassWithClassIdRespStatus: 0
      }

    case GET_WEEK_CLASS_DAYS_FOR_TEACHER_INIT:
      return {
        ...state,
        isGettingWeekClassDaysForTeacher: true,
        getWeekClassDaysForTeacherError: false,
        getWeekClassDaysForTeacherRespStatus: 0,
        getWeekClassDaysForTeacherResp: [],
      }

    case GET_WEEK_CLASS_DAYS_FOR_TEACHER_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingWeekClassDaysForTeacher: false,
        getWeekClassDaysForTeacherError: false,
        getWeekClassDaysForTeacherRespStatus: 200,
        getWeekClassDaysForTeacherResp: data,
      }
    }

    case GET_WEEK_CLASS_DAYS_FOR_TEACHER_FAILURE:
      return {
        ...state,
        isGettingWeekClassDaysForTeacher: false,
        getWeekClassDaysForTeacherError: true,
        getWeekClassDaysForTeacherRespStatus: 400,
        getWeekClassDaysForTeacherErrorMsg: action.error,
      }

    case GET_WEEK_CLASS_DAYS_FOR_ADMIN_INIT:
      return {
        ...state,
        isGettingWeekClassDaysForAdmin: true,
        getWeekClassDaysForAdminError: false,
        getWeekClassDaysForAdminRespStatus: 0,
        getWeekClassDaysForAdminResp: [],
      }

    case GET_WEEK_CLASS_DAYS_FOR_ADMIN_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingWeekClassDaysForAdmin: false,
        getWeekClassDaysForAdminError: false,
        getWeekClassDaysForAdminRespStatus: 200,
        getWeekClassDaysForAdminResp: data,
      }
    }

    case GET_WEEK_CLASS_DAYS_FOR_ADMIN_FAILURE:
      return {
        ...state,
        isGettingWeekClassDaysForAdmin: false,
        getWeekClassDaysForAdminError: true,
        getWeekClassDaysForAdminRespStatus: 400,
        getWeekClassDaysForAdminErrorMsg: action.error,
      }

    case GET_SUBJECTS_INIT:
      return {
        ...state,
        isGettingSubjects: true,
        getSubjectsError: false,
        getSubjectsRespStatus: 0,
        getSubjectsResp: [],
      }

    case GET_SUBJECTS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingSubjects: false,
        getSubjectsError: false,
        getSubjectsRespStatus: 200,
        getSubjectsResp: data,
      }
    }

    case GET_SUBJECTS_FAILURE:
      return {
        ...state,
        isGettingSubjects: false,
        getSubjectsError: true,
        getSubjectsRespStatus: 400,
        getSubjectsErrorMsg: action.error,
      }

    case GET_SUBJECT_MENTOR_INIT:
      return {
        ...state,
        isGettingSubjectMentor: true,
        getSubjectMentorError: false,
        getSubjectMentorRespStatus: 0,
        getSubjectMentorResp: [],
      }

    case GET_SUBJECT_MENTOR_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingSubjectMentor: false,
        getSubjectMentorError: false,
        getSubjectMentorRespStatus: 200,
        getSubjectMentorResp: data,
      }
    }

    case GET_SUBJECT_MENTOR_FAILURE:
      return {
        ...state,
        isGettingSubjectMentor: false,
        getSubjectMentorError: true,
        getSubjectMentorRespStatus: 400,
        getSubjectMentorErrorMsg: action.error,
      }

    case GET_MENTOR_INIT:
      return {
        ...state,
        isGettingMentor: true,
        getMentorError: false,
        getMentorRespStatus: 0,
        getMentorResp: [],
      }

    case GET_MENTOR_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingMentor: false,
        getMentorError: false,
        getMentorRespStatus: 200,
        getMentorResp: data,
      }
    }

    case GET_MENTOR_FAILURE:
      return {
        ...state,
        isGettingMentor: false,
        getMentorError: true,
        getMentorRespStatus: 400,
        getMentorErrorMsg: action.error,
      }

    case ADD_CLASS_DAY_INIT:
      return {
        ...state,
        isAddingClassDay: true,
        addClassDayError: false,
        addClassDayRespStatus: 0,
        addClassDayResp: [],
      }

    case ADD_CLASS_DAY_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingClassDay: false,
        addClassDayError: false,
        addClassDayRespStatus: 200,
        addClassDayResp: data,
      }
    }

    case ADD_CLASS_DAY_FAILURE:
      return {
        ...state,
        isAddingClassDay: false,
        addClassDayError: true,
        addClassDayRespStatus: 400,
        addClassDayErrorMsg: action.error,
      }

    case UPDATE_CLASS_DAY_INIT:
      return {
        ...state,
        isUpdatingClassDay: true,
        updateClassDayError: false,
        updateClassDayRespStatus: 0,
        updateClassDayResp: [],
      }

    case UPDATE_CLASS_DAY_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isUpdatingClassDay: false,
        updateClassDayError: false,
        updateClassDayRespStatus: 200,
        updateClassDayResp: data,
      }
    }

    case UPDATE_CLASS_DAY_FAILURE:
      return {
        ...state,
        isUpdatingClassDay: false,
        updateClassDayError: true,
        updateClassDayRespStatus: 400,
        updateClassDayErrorMsg: action.error,
      }

    case RESET_CLASS_DAY_RESPONSES:
      return {
        ...state,
        updateClassDayRespStatus: 0
      }

    case GET_CLASS_DAY_INIT:
      return {
        ...state,
        isGettingClassDay: true,
        getClassDayError: false,
        getClassDayRespStatus: 0,
        getClassDayResp: [],
      }

    case GET_CLASS_DAY_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingClassDay: false,
        getClassDayError: false,
        getClassDayRespStatus: 200,
        getClassDayResp: data,
      }
    }

    case GET_CLASS_DAY_FAILURE:
      return {
        ...state,
        isGettingClassDay: false,
        getClassDayError: true,
        getClassDayRespStatus: 400,
        getClassDayErrorMsg: action.error,
      }

    case ADD_USER_TIMESTAMP_INIT:
      return {
        ...state,
        isAddingUserTimestamp: true,
        addUserTimestampError: false,
        addUserTimestampRespStatus: 0,
        addUserTimestampResp: [],
      }

    case ADD_USER_TIMESTAMP_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingUserTimestamp: false,
        addUserTimestampError: false,
        addUserTimestampRespStatus: 200,
        addUserTimestampResp: data,
      }
    }

    case ADD_USER_TIMESTAMP_FAILURE:
      return {
        ...state,
        isAddingUserTimestamp: false,
        addUserTimestampError: true,
        addUserTimestampRespStatus: 400,
        addUserTimestampErrorMsg: action.error,
      }

    case ADD_USER_LEAVE_TIMESTAMP_INIT:
      return {
        ...state,
        isAddingUserLeaveTimestamp: true,
        addUserLeaveTimestampRespStatus: 0,
        addUserLeaveTimestampResp: [],
      }

    case ADD_USER_LEAVE_TIMESTAMP_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingUserLeaveTimestamp: false,
        addUserLeaveTimestampError: false,
        addUserLeaveTimestampRespStatus: 200,
        addUserLeaveTimestampResp: data,
      }
    }

    case ADD_USER_LEAVE_TIMESTAMP_FAILURE:
      return {
        ...state,
        isAddingUserLeaveTimestamp: false,
        addUserLeaveTimestampError: true,
        addUserLeaveTimestampRespStatus: 400,
        addUserLeaveTimestampErrorMsg: action.error,
      }

    case DELETE_CLASS_DAY_INIT:
      return {
        ...state,
        isDeletingClassDay: true,
        deleteClassDayError: false,
        deleteClassDayRespStatus: 0,
        deleteClassDayResp: [],
      }

    case DELETE_CLASS_DAY_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isDeletingClassDay: false,
        deleteClassDayError: false,
        deleteClassDayRespStatus: 200,
        deleteClassDayResp: data,
      }
    }

    case DELETE_CLASS_DAY_FAILURE:
      return {
        ...state,
        isDeletingClassDay: false,
        deleteClassDayError: true,
        deleteClassDayRespStatus: 400,
        deleteClassDayErrorMsg: action.error,
      }

    case CREDIT_COINS_FOR_STUDENTS_INIT:
      return {
        ...state,
        isCreditingCoinsForStudents: true,
        creditCoinsForStudentsError: false,
        creditCoinsForStudentsRespStatus: 0,
        creditCoinsForStudentsResp: [],
      }

    case CREDIT_COINS_FOR_STUDENTS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isCreditingCoinsForStudents: false,
        creditCoinsForStudentsError: false,
        creditCoinsForStudentsRespStatus: 200,
        creditCoinsForStudentsResp: data,
      }
    }

    case CREDIT_COINS_FOR_STUDENTS_FAILURE:
      return {
        ...state,
        isCreditingCoinsForStudents: false,
        creditCoinsForStudentsError: true,
        creditCoinsForStudentsRespStatus: 400,
        creditCoinsForStudentsErrorMsg: action.error,
      }

    case UPLOAD_RECORDING_TO_DRIVE_INIT:
      return {
        ...state,
        isUploadingRecordingToDrive: true,
        uploadRecordingToDriveError: false,
        uploadRecordingToDriveRespStatus: 0,
        uploadRecordingToDriveResp: [],
      }

    case UPLOAD_RECORDING_TO_DRIVE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isUploadingRecordingToDrive: false,
        uploadRecordingToDriveError: false,
        uploadRecordingToDriveRespStatus: 200,
        uploadRecordingToDriveResp: data,
      }
    }

    case UPLOAD_RECORDING_TO_DRIVE_FAILURE:
      return {
        ...state,
        isUploadingRecordingToDrive: false,
        uploadRecordingToDriveError: true,
        uploadRecordingToDriveRespStatus: 400,
        uploadRecordingToDriveErrorMsg: action.error,
      }

    case ADD_MEETING_ID_TO_FIREBASE_INIT:
      return {
        ...state,
        isAddingMeetingIdToFirebase: true,
        addMeetingIdToFirebaseError: false,
        addMeetingIdToFirebaseRespStatus: 0,
        addMeetingIdToFirebaseResp: [],
      }

    case ADD_MEETING_ID_TO_FIREBASE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingMeetingIdToFirebase: false,
        addMeetingIdToFirebaseError: false,
        addMeetingIdToFirebaseRespStatus: 200,
        addMeetingIdToFirebaseResp: data,
      }
    }

    case ADD_MEETING_ID_TO_FIREBASE_FAILURE:
      return {
        ...state,
        isAddingMeetingIdToFirebase: false,
        addMeetingIdToFirebaseError: true,
        addMeetingIdToFirebaseRespStatus: 400,
        addMeetingIdToFirebaseErrorMsg: action.error,
      }

    case ADD_DOCUMENTS_TO_CLASS_DAY_INIT:
      return {
        ...state,
        isAddingDocumentsToClassDay: true,
        addDocumentsToClassDayError: false,
        addDocumentsToClassDayRespStatus: 0,
        addDocumentsToClassDayResp: [],
      }

    case ADD_DOCUMENTS_TO_CLASS_DAY_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingDocumentsToClassDay: false,
        addDocumentsToClassDayError: false,
        addDocumentsToClassDayRespStatus: 200,
        addDocumentsToClassDayResp: data,
      }
    }

    case ADD_DOCUMENTS_TO_CLASS_DAY_FAILURE:
      return {
        ...state,
        isAddingDocumentsToClassDay: false,
        addDocumentsToClassDayError: true,
        addDocumentsToClassDayRespStatus: 400,
        addDocumentsToClassDayErrorMsg: action.error,
      }

    case RESET_HLS_LINK_STATUS:
      return {
        ...state,
        removeHlsLinkFromFirebaseRespStatus: 0
      }

    default:
      return state;
  }
};

export default classes;
