export const GET_OTP_INIT = 'GET_OTP_INIT';
export const GET_OTP_SUCCESS = 'GET_OTP_SUCCESS';
export const GET_OTP_FAILURE = 'GET_OTP_FAILURE';

export const VERIFY_OTP_INIT = 'VERIFY_OTP_INIT';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

export const FIREBASE_SIGNED_OUT = "FIREBASE_SIGNED_OUT";

export const GET_USER_INIT = 'GET_USER_INIT';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const GET_CLASS_DAYS_FOR_TEACHER_INIT = 'GET_CLASS_DAYS_FOR_TEACHER_INIT';
export const GET_CLASS_DAYS_FOR_TEACHER_SUCCESS = 'GET_CLASS_DAYS_FOR_TEACHER_SUCCESS';
export const GET_CLASS_DAYS_FOR_TEACHER_FAILURE = 'GET_CLASS_DAYS_FOR_TEACHER_FAILURE';

export const GET_CLASS_DAYS_FOR_STUDENT_INIT = 'GET_CLASS_DAYS_FOR_STUDENT_INIT';
export const GET_CLASS_DAYS_FOR_STUDENT_SUCCESS = 'GET_CLASS_DAYS_FOR_STUDENT_SUCCESS';
export const GET_CLASS_DAYS_FOR_STUDENT_FAILURE = 'GET_CLASS_DAYS_FOR_STUDENT_FAILURE';

export const GET_HLS_LINK_FOR_CLASS_INIT = 'GET_HLS_LINK_FOR_CLASS_INIT';
export const GET_HLS_LINK_FOR_CLASS_SUCCESS = 'GET_HLS_LINK_FOR_CLASS_SUCCESS';
export const GET_HLS_LINK_FOR_CLASS_FAILURE = 'GET_HLS_LINK_FOR_CLASS_FAILURE';

export const ADD_HLS_LINK_TO_FIREBASE_INIT = 'ADD_HLS_LINK_TO_FIREBASE_INIT';
export const ADD_HLS_LINK_TO_FIREBASE_SUCCESS = 'ADD_HLS_LINK_TO_FIREBASE_SUCCESS';
export const ADD_HLS_LINK_TO_FIREBASE_FAILURE = 'ADD_HLS_LINK_TO_FIREBASE_FAILURE';

export const REMOVE_HLS_LINK_FROM_FIREBASE_INIT = 'REMOVE_HLS_LINK_FROM_FIREBASE_INIT';
export const REMOVE_HLS_LINK_FROM_FIREBASE_SUCCESS = 'REMOVE_HLS_LINK_FROM_FIREBASE_SUCCESS';
export const REMOVE_HLS_LINK_FROM_FIREBASE_FAILURE = 'REMOVE_HLS_LINK_FROM_FIREBASE_FAILURE';

export const JOIN_HLS_INIT = 'JOIN_HLS_INIT';
export const LEAVE_HLS_INIT = 'LEAVE_HLS_INIT';

export const GET_CMS_DATA_INIT = 'GET_CMS_DATA_INIT';
export const GET_CMS_DATA_SUCCESS = 'GET_CMS_DATA_SUCCESS';
export const GET_CMS_DATA_FAILURE = 'GET_CMS_DATA_FAILURE';

export const SEND_PUSH_NOTIFICATION_INIT = "SEND_PUSH_NOTIFICATION_INIT"
export const SEND_PUSH_NOTIFICATION_SUCCESS = "SEND_PUSH_NOTIFICATION_SUCCESS"
export const SEND_PUSH_NOTIFICATION_FAILURE = "SEND_PUSH_NOTIFICATION_FAILURE"

export const SEND_PUSH_NOTIFICATION_TO_TOPIC_INIT = "SEND_PUSH_NOTIFICATION_TO_TOPIC_INIT"
export const SEND_PUSH_NOTIFICATION_TO_TOPIC_SUCCESS = "SEND_PUSH_NOTIFICATION_TO_TOPIC_SUCCESS"
export const SEND_PUSH_NOTIFICATION_TO_TOPIC_FAILURE = "SEND_PUSH_NOTIFICATION_TO_TOPIC_FAILURE"

export const GET_CLASS_WITH_CLASS_ID_INIT = "GET_CLASS_WITH_CLASS_ID_INIT"
export const GET_CLASS_WITH_CLASS_ID_SUCCESS = "GET_CLASS_WITH_CLASS_ID_SUCCESS"
export const GET_CLASS_WITH_CLASS_ID_FAILURE = "GET_CLASS_WITH_CLASS_ID_FAILURE"

export const RESET_CLASS_WITH_CLASS_ID = "RESET_CLASS_WITH_CLASS_ID"

export const GET_WEEK_CLASS_DAYS_FOR_TEACHER_INIT = "GET_WEEK_CLASS_DAYS_FOR_TEACHER_INIT";
export const GET_WEEK_CLASS_DAYS_FOR_TEACHER_SUCCESS = "GET_WEEK_CLASS_DAYS_FOR_TEACHER_SUCCESS";
export const GET_WEEK_CLASS_DAYS_FOR_TEACHER_FAILURE = "GET_WEEK_CLASS_DAYS_FOR_TEACHER_FAILURE";

export const GET_WEEK_CLASS_DAYS_FOR_ADMIN_INIT = "GET_WEEK_CLASS_DAYS_FOR_ADMIN_INIT";
export const GET_WEEK_CLASS_DAYS_FOR_ADMIN_SUCCESS = "GET_WEEK_CLASS_DAYS_FOR_ADMIN_SUCCESS";
export const GET_WEEK_CLASS_DAYS_FOR_ADMIN_FAILURE = "GET_WEEK_CLASS_DAYS_FOR_ADMIN_FAILURE";

export const GET_SUBJECTS_INIT = "GET_SUBJECTS_INIT"
export const GET_SUBJECTS_SUCCESS = "GET_SUBJECTS_SUCCESS"
export const GET_SUBJECTS_FAILURE = "GET_SUBJECTS_FAILURE"

export const GET_SUBJECT_MENTOR_INIT = "GET_SUBJECT_MENTOR_INIT"
export const GET_SUBJECT_MENTOR_SUCCESS = "GET_SUBJECT_MENTOR_SUCCESS"
export const GET_SUBJECT_MENTOR_FAILURE = "GET_SUBJECT_MENTOR_FAILURE"

export const GET_MENTOR_INIT = "GET_MENTOR_INIT"
export const GET_MENTOR_SUCCESS = "GET_MENTOR_SUCCESS"
export const GET_MENTOR_FAILURE = "GET_MENTOR_FAILURE"

export const ADD_CLASS_DAY_INIT = "ADD_CLASS_DAY_INIT"
export const ADD_CLASS_DAY_SUCCESS = "ADD_CLASS_DAY_SUCCESS"
export const ADD_CLASS_DAY_FAILURE = "ADD_CLASS_DAY_FAILURE"

export const UPDATE_CLASS_DAY_INIT = "UPDATE_CLASS_DAY_INIT"
export const UPDATE_CLASS_DAY_SUCCESS = "UPDATE_CLASS_DAY_SUCCESS"
export const UPDATE_CLASS_DAY_FAILURE = "UPDATE_CLASS_DAY_FAILURE"

export const GET_CLASS_DAY_INIT = "GET_CLASS_DAY_INIT"
export const GET_CLASS_DAY_SUCCESS = "GET_CLASS_DAY_SUCCESS"
export const GET_CLASS_DAY_FAILURE = "GET_CLASS_DAY_FAILURE"

export const RESET_CLASS_DAY_RESPONSES = "RESET_CLASS_DAY_RESPONSES"

export const ADD_USER_TIMESTAMP_INIT = "ADD_USER_TIMESTAMP_INIT"
export const ADD_USER_TIMESTAMP_SUCCESS = "ADD_USER_TIMESTAMP_SUCCESS"
export const ADD_USER_TIMESTAMP_FAILURE = "ADD_USER_TIMESTAMP_FAILURE"

export const ADD_USER_LEAVE_TIMESTAMP_INIT = "ADD_USER_LEAVE_TIMESTAMP_INIT"
export const ADD_USER_LEAVE_TIMESTAMP_SUCCESS = "ADD_USER_LEAVE_TIMESTAMP_SUCCESS"
export const ADD_USER_LEAVE_TIMESTAMP_FAILURE = "ADD_USER_LEAVE_TIMESTAMP_FAILURE"

export const DELETE_CLASS_DAY_INIT = "DELETE_CLASS_DAY_INIT"
export const DELETE_CLASS_DAY_SUCCESS = "DELETE_CLASS_DAY_SUCCESS"
export const DELETE_CLASS_DAY_FAILURE = "DELETE_CLASS_DAY_FAILURE"

export const CREDIT_COINS_FOR_STUDENTS_INIT = "CREDIT_COINS_FOR_STUDENTS_INIT"
export const CREDIT_COINS_FOR_STUDENTS_SUCCESS = "CREDIT_COINS_FOR_STUDENTS_SUCCESS"
export const CREDIT_COINS_FOR_STUDENTS_FAILURE = "CREDIT_COINS_FOR_STUDENTS_FAILURE"

export const UPLOAD_RECORDING_TO_DRIVE_INIT = "UPLOAD_RECORDING_TO_DRIVE_INIT"
export const UPLOAD_RECORDING_TO_DRIVE_SUCCESS = "UPLOAD_RECORDING_TO_DRIVE_SUCCESS"
export const UPLOAD_RECORDING_TO_DRIVE_FAILURE = "UPLOAD_RECORDING_TO_DRIVE_FAILURE"

export const ADD_MEETING_ID_TO_FIREBASE_INIT = "ADD_MEETING_ID_TO_FIREBASE_INIT"
export const ADD_MEETING_ID_TO_FIREBASE_SUCCESS = "ADD_MEETING_ID_TO_FIREBASE_SUCCESS"
export const ADD_MEETING_ID_TO_FIREBASE_FAILURE = "ADD_MEETING_ID_TO_FIREBASE_FAILURE"

export const GET_POLLS_INIT = "GET_POLLS_INIT"
export const GET_POLLS_SUCCESS = "GET_POLLS_SUCCESS"
export const GET_POLLS_FAILURE = "GET_POLLS_FAILURE"

export const RESET_POLL = "RESET_POLL"

export const ADD_POLLS_INIT = "ADD_POLLS_INIT"
export const ADD_POLLS_SUCCESS = "ADD_POLLS_SUCCESS"
export const ADD_POLLS_FAILURE = "ADD_POLLS_FAILURE"

export const END_POLL_INIT = "END_POLL_INIT"
export const END_POLL_SUCCESS = "END_POLL_SUCCESS"
export const END_POLL_FAILURE = "END_POLL_FAILURE"

export const ADD_DOCUMENTS_TO_CLASS_DAY_INIT = "ADD_DOCUMENTS_TO_CLASS_DAY_INIT"
export const ADD_DOCUMENTS_TO_CLASS_DAY_SUCCESS = "ADD_DOCUMENTS_TO_CLASS_DAY_SUCCESS"
export const ADD_DOCUMENTS_TO_CLASS_DAY_FAILURE = "ADD_DOCUMENTS_TO_CLASS_DAY_FAILURE"

export const RESET_HLS_LINK_STATUS = "RESET_HLS_LINK_STATUS"