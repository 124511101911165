import { Box, Button, Modal } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminClassItem from "../../components/admin/adminClassItem";
import AdminClassModal from "../../components/admin/adminModal";
import Navbar from "../../components/navbar/navbar";
import ScheduleItem from "../../components/schedule/scheduleItem";
import ScheduleModal from "../../components/schedule/scheduleModal";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import { deleteClassDay, getClassDay, getSubjectMentor, getSubjects, getWeekClassDaysForAdmin } from "../../redux/classes/classesActions";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: "10px",
  boxShadow: 0,
  pt: 2,
  px: 4,
  pb: 3,
};

function ChildModal() {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen}>Open Child Modal</Button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 200 }}>
          <h2 id="child-modal-title">Text in a child modal</h2>
          <p id="child-modal-description">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          </p>
          <Button onClick={handleClose}>Close Child Modal</Button>
        </Box>
      </Modal>
    </>
  );
}

const AdminIndex = () => {
  const { user } = useUserAuth();
  const dispatch = useDispatch();
  const [modalContent, setModalContent] = useState<any>({});

  const [startingDate, setStartingDate] = useState<any>(new Date())
  const [selectedClassDay, setSelectedClassDay] = useState<string>("")



  const {
    isGettingWeekClassDaysForAdmin,
    getWeekClassDaysForAdminResp,
    getWeekClassDaysForAdminRespStatus,
    getWeekClassDaysForAdminError,
    getWeekClassDaysForAdminErrorMsg,
    isGettingSubjects,
    getSubjectsError,
    getSubjectsResp,
    getSubjectsRespStatus,
    getSubjectsErrorMsg,
    isUpdatingClassDay,
    updateClassDayRespStatus,
    isAddingClassDay,
    addClassDayRespStatus,
    isGettingSubjectMentor,
    getSubjectMentorResp,
    getSubjectMentorRespStatus,
    getSubjectMentorError,
    getSubjectMentorErrorMsg,
    isDeletingClassDay,
    deleteClassDayResp,
    deleteClassDayRespStatus,
    deleteClassDayError,
    deleteClassDayErrorMsg
  } = useSelector((state: any) => state.classes);

  const handleEdit = (data: any) => {
    setModalContent(data);
    dispatch(getSubjectMentor({
      subjectId: data.subjectId
    }))
  }

  const handleDelete = (data: any) => {
    dispatch(deleteClassDay({
      classDayId: data
    }))
  }

  useEffect(() => {
    if (getSubjectMentorRespStatus === 200) {
      setModalOpen(true)
    }
  }, [isGettingSubjectMentor]);

  useEffect(() => {
    if (getSubjectsRespStatus === 200) {
    } else if (getSubjectsError) {
      alert(getSubjectsErrorMsg)
    }
  }, [isGettingSubjects])

  useEffect(() => {
    dispatch(getSubjects({}));
  }, [])

  useEffect(() => {
    dispatch(getWeekClassDaysForAdmin({
      teacherId: user.uid,
      date: startingDate
    }));
  }, [startingDate])

  useEffect(() => {
    if (updateClassDayRespStatus === 200 || addClassDayRespStatus === 200 || deleteClassDayRespStatus === 200) {
      dispatch(getWeekClassDaysForAdmin({
        teacherId: user.uid,
        date: startingDate
      }))
    }
  }, [isUpdatingClassDay, isAddingClassDay, isDeletingClassDay])

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  return (
    <div className="custom-bg">
      <Navbar />
      <div className="h-[calc(100vh-100px)] overflow-y-auto md:px-10">
        <div className="text-xl text-white md:px-10">
          Weekly Schedule
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 p-10 gap-5">
          {getWeekClassDaysForAdminResp.map((classDateContainer: any) => {
            return (
              <AdminClassItem classDateContainer={classDateContainer} setModalOpen={setModalOpen} setModalContent={setModalContent} selectedClassDay={selectedClassDay} setSelectedClassDay={setSelectedClassDay} handleEdit={handleEdit} handleDelete={handleDelete} />
            )
          })}
        </div>
        <div className="flex  justify-center gap-5 items-center mt-5 mb-5">
          <div className="bg-white opacity-[70%] px-10 py-3 cursor-pointer rounded-[10px]" onClick={() => setStartingDate(new Date(startingDate.setDate(startingDate.getDate() - 7)))}>
            Prev
          </div>
          <div className="bg-white opacity-[70%] px-10 py-3 cursor-pointer rounded-[10px]" onClick={() => setStartingDate(new Date(startingDate.setDate(startingDate.getDate() + 7)))}>
            Next
          </div>
        </div>
        {/* <Button onClick={() => setModalOpen(true)}>Open modal</Button> */}
      </div>
      <AdminClassModal modalOpen={modalOpen} setModalOpen={setModalOpen} modalContent={modalContent} setModalContent={setModalContent} />
    </div>
  )
}

export default AdminIndex;