import {
  GET_POLLS_INIT,
  GET_POLLS_SUCCESS,
  GET_POLLS_FAILURE,
  ADD_POLLS_INIT,
  ADD_POLLS_SUCCESS,
  ADD_POLLS_FAILURE,
  END_POLL_INIT,
  END_POLL_SUCCESS,
  END_POLL_FAILURE,
  RESET_POLL,
} from '../actionTypes';
import { IPollsState } from '../../models/polls/polls';

const initialState = {
  isGettingPolls: false,
  getPollsError: false,
  getPollsErrorMsg: '',
  getPollsRespStatus: 0,
  getPollsResp: [],
  isAddingPolls: false,
  addPollsError: false,
  addPollsErrorMsg: '',
  addPollsRespStatus: 0,
  addPollsResp: [],
  isEndingPoll: false,
  endPollError: false,
  endPollErrorMsg: '',
  endPollRespStatus: 0,
  endPollResp: [],
}

const polls = (state: IPollsState = initialState, action: any) => {
  switch (action.type) {

    case GET_POLLS_INIT:
      return {
        ...state,
        isGettingPolls: true,
        getPollsError: false,
        getPollsRespStatus: 0,
        getPollsResp: [],
      }

    case GET_POLLS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingPolls: false,
        getPollsError: false,
        getPollsRespStatus: 200,
        getPollsResp: data,
      }
    }

    case GET_POLLS_FAILURE:
      return {
        ...state,
        isGettingPolls: false,
        getPollsError: true,
        getPollsRespStatus: 400,
        getPollsErrorMsg: action.error,
      }

    case ADD_POLLS_INIT:
      return {
        ...state,
        isAddingPolls: true,
        addPollsError: false,
        addPollsRespStatus: 0,
        addPollsResp: [],
      }

    case ADD_POLLS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingPolls: false,
        addPollsError: false,
        addPollsRespStatus: 200,
        addPollsResp: data,
      }
    }

    case ADD_POLLS_FAILURE:
      return {
        ...state,
        isAddingPolls: false,
        addPollsError: true,
        addPollsRespStatus: 400,
        addPollsErrorMsg: action.error,
      }

    case END_POLL_INIT:
      return {
        ...state,
        isEndingPoll: true,
        endPollError: false,
        endPollRespStatus: 0,
        endPollResp: [],
      }

    case END_POLL_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isEndingPoll: false,
        endPollError: false,
        endPollRespStatus: 200,
        endPollResp: data,
      }
    }

    case END_POLL_FAILURE:
      return {
        ...state,
        isEndingPoll: false,
        endPollError: true,
        endPollRespStatus: 400,
        endPollErrorMsg: action.error,
      }

    case RESET_POLL:
      return {
        ...state,
        addPollsRespStatus: 0
      }

    default:
      return state;
  }
};

export default polls;
