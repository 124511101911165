import { all, spawn } from "redux-saga/effects";
import * as authSagas from "./auth/authSaga";
import * as classesSagas from "./classes/classesSaga";
import * as pollsSagas from "./polls/pollsSagas";

export default function* rootSaga() {
  yield all([
    ...Object.values(authSagas),
    ...Object.values(classesSagas),
    ...Object.values(pollsSagas),
  ].map(spawn));
}