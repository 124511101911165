import { IconButton } from "@material-ui/core";
import { Close, Send } from "@material-ui/icons";
import { ref, set, push } from "firebase/database";
import { useState } from "react";
import { realTimeDB } from "../firebaseConfig";
import { useUserAuth } from "../hooks/useAuthContextProvider";
import { formatAMPM, nameTructed } from "../utils/helper";
import { useSelector } from "react-redux";
import { useSidepaneToggle } from "./AppData/useSidepane";
import { SIDE_PANE_OPTIONS } from "../common/constants";

type ChatProps = {
  setShowMessages?: (val: boolean) => void;
  handleClose?: () => void;
  currentlyClickedClassDayId: string;
  messages: Array<any>;
  divRef: any
}

const Chat = ({
  setShowMessages,
  handleClose,
  currentlyClickedClassDayId,
  messages,
  divRef
}: any) => {
  const { user, userDetails } = useUserAuth()
  const classDayId = currentlyClickedClassDayId;
  const [newMessage, setNewMessage] = useState<string>("")
  const messageRef = ref(realTimeDB, `chat/${classDayId}`)
  const {
    getMentorResp
  } = useSelector((state: any) => state.classes)
  const sendMessage = (e: any) => {
    e.preventDefault()
    if (newMessage) {
      const messageDoc = push(messageRef)
      set(messageDoc, {
        message: newMessage,
        senderId: user.uid,
        senderName: userDetails?.userType === 2 ? `${getMentorResp?.mentorName} ${getMentorResp?.mentorGenderText}` : userDetails?.name,
        createdAt: (new Date()).toISOString(),
        isMentor: userDetails?.userType === 2 ? true : false,
        messageId: messageDoc.key
      })
    }
    setNewMessage("")
  }

  const toggleChat = useSidepaneToggle(SIDE_PANE_OPTIONS.CHAT);

  return (
    <div className="h-full">
      <div className="h-full">
        <div className="flex justify-between text-white font-bold text-lg">
          Chat
          {<IconButton
            onClick={toggleChat}
          >
            <Close fontSize={"small"} />
          </IconButton>}
        </div>
        <div className={"px-2 overflow-y-auto" + ` ${" h-[calc(100%-93px)]"}`}>
          {messages.map((message: any) => {
            const localSender = user.uid === message.senderId
            return (
              <div
                className={`flex ${localSender ? "justify-end" : "justify-start"} mt-4`}
                style={{
                  maxWidth: "100%",
                }}
              >
                <div
                  className={`flex ${localSender ? "items-end" : "items-start"
                    } flex-col py-1 px-2 rounded-md bg-gray-700`}
                  style={{ maxWidth: "70%" }}
                >
                  <p style={{ color: "#ffffff80" }}>
                    {localSender ? "You" : message.isMentor ? message.senderName?.split(" ")?.[0] + ` ${getMentorResp?.mentorGenderText || ""}` : nameTructed(message?.senderName, 15)}
                  </p>
                  <div>
                    <p className={(localSender ? "text-end " : "text-start ") + "inline-block whitespace-pre-wrap break-words text-right text-white"}>
                      {message?.message}
                    </p>
                  </div>
                  <div className="mt-1">
                    <p className="text-xs italic" style={{ color: "#ffffff80" }}>
                      {formatAMPM(new Date(message?.createdAt))}
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
          <div ref={divRef} className="pt-2" />
        </div>
        <form onSubmit={sendMessage}>
          <div className="flex items-center gap-2 justify-between w-full">
            <input type="text" value={newMessage} placeholder="Enter message" onChange={(e: any) => setNewMessage(e.target.value)} className="w-full px-5 py-2 border-none bg-gray-600 text-white rounded focus:outline-blue-400" />
            <button type="submit" className="bg-gray-600 text-white pr-2">
              <Send />
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Chat;
