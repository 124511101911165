type ScheduleItemProps = {
  classDateContainer: any;
  setModalOpen: (val: boolean) => void;
  setModalContent: (val: any) => void;
}

const ScheduleItem = ({
  classDateContainer,
  setModalOpen,
  setModalContent
}: ScheduleItemProps) => {

  const handleEdit = (data: any) => {
    setModalContent({ containerDate: Object.keys(classDateContainer)[0], ...data });
    setModalOpen(true)
  }

  return (
    <div className="bg-white bg-opacity-70 p-5 rounded-[10px]">
      <div className="text-2xl mb-5">
        {Object.keys(classDateContainer)[0]}
      </div>
      {classDateContainer[Object.keys(classDateContainer)[0]].map((classDay: any) => {
        return (
          <div className="flex justify-between items-center mb-2">
            <div className="text-xl">
              {classDay.subjectName}
            </div>
            <div className="flex items-center gap-3">
              <div className="text-xl">
                {classDay.date.toDate().toLocaleString('en-US', { hour: 'numeric', minute: "numeric", hour12: true })}
              </div>
              <div className="bg-white text-black px-5 py-2 rounded cursor-pointer font-bold" onClick={() => handleEdit(classDay)}>
                Edit
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ScheduleItem;