import {
  put, call,
  takeLatest,
} from 'redux-saga/effects';
import { db } from '../../firebaseConfig';
import { collection, getDocs, query, where, doc, getDoc, updateDoc, setDoc, addDoc, deleteDoc, orderBy, limit } from "firebase/firestore";
import {
  GET_POLLS_INIT,
  ADD_POLLS_INIT,
  END_POLL_INIT
} from '../actionTypes';
import {
  getPollsSuccess,
  getPollsFailure,
  addPollsSuccess,
  addPollsFailure,
  endPollSuccess,
  endPollFailure,
} from './pollsActions';
import { getRequest, postRequest } from '../../utils/apiRequests';
import axios from 'axios';
import { baseURL, endPoints } from '../../utils/apiEndPoints';

function* getPolls(action: any): any {
  try {
    const get = async () => {
      const docRef = query(collection(db, "ClassPolls"), where("classDayId", "==", action.payload.params.classDayId))
      const docSnap = await getDocs(docRef);
      if (docSnap.size > 0) {
        let arr: any = []
        docSnap.forEach((snap) => {
          arr.push(snap.data())
        })
        return arr;
      } else {
        return "";
      }
    }

    const data = yield call(get);
    if (data) {
      yield put(getPollsSuccess(data));
    } else {
      yield put(getPollsFailure("Something went wrong"));
    }
  } catch (err: any) {
    yield put(getPollsFailure("Something went wrong"));
  }
}

export function* getPollsSaga() {
  yield takeLatest(GET_POLLS_INIT, getPolls);
}

function* addPolls(action: any): any {
  try {
    const add = async () => {
      let docRef = doc(collection(db, "ClassPolls"))
      const docSnap = await setDoc(docRef, {
        ...action.payload.params.data,
        pollId: docRef.id
      });
      return true
    }

    const data = yield call(add);
    if (data) {
      yield put(addPollsSuccess(data));
    } else {
      yield put(addPollsFailure("Something went wrong"));
    }
  } catch (err: any) {
    yield put(addPollsFailure("Something went wrong"));
  }
}

export function* addPollsSaga() {
  yield takeLatest(ADD_POLLS_INIT, addPolls);
}

function* endPoll(action: any): any {
  try {
    const end = async () => {
      try {
        const docRef = query(collection(db, "ClassPolls"), where("classDayId", "==", action.payload.params.classDayId))
        const all = await getDocs(docRef)
        const docs = all.docs.sort((a: any, b: any) => b.data().createdAt.toDate() - a.data().createdAt.toDate())
        const userRespons = docs[0].data().userResponses.sort((a: any, b: any) => a.data().createdAt.toDate() - b.data().createdAt.toDate())



        let temp: any = [];
        for (let i = 0; i < userRespons.length; i++) {
          if (userRespons[i].answer[0] === docs[0].data().answers[0]) {
            userRespons[i] = {
              ...userRespons[i],
              correct: true
            }
            temp.push(userRespons[i])
          }
        }

        const userRewards: any = {}

        for (let i = 0; i < temp.length; i++) {
          const userRef = doc(db, "user", temp[i].userId)
          const userGet = await getDoc(userRef)
          const userData: any = userGet.data()
          if (i < 3) {
            await axios.post(`https://snipe-nest-lfvnq7enja-el.a.run.app/history/rewards/teacher/${temp[i].userId}`, {
              type: "19",
              primaryItemId: action.payload.params.classId,
              secondaryItemId: action.payload.params.classDayId,
              token: {
                coins: 0,
                silverTrophy: i === 0 ? (docs[0].data().points / 10) * 5 : i === 1 ? (docs[0].data().points / 10) * 3 : i === 2 ? (docs[0].data().points / 10) * 2 : 5,
                goldTrophy: 0
              },
              metaData: { className: action.payload.params.className }
            }, {
              headers: {
                "Authorization": `Bearer ${action.payload.params.token}`
              }
            })
            userRewards[temp[i].userId] = {
              createdAt: new Date(),
              token: {
                coins: 0,
                trophies: {
                  silver: i === 0 ? (docs[0].data().points / 10) * 5 : i === 1 ? (docs[0].data().points / 10) * 3 : i === 2 ? (docs[0].data().points / 10) * 2 : 5,
                  gold: 0
                }
              },
              ranked: true
            }
          } else {
            await axios.post(`https://snipe-nest-lfvnq7enja-el.a.run.app/history/rewards/teacher/${temp[i].userId}`, {
              type: "19",
              primaryItemId: action.payload.params.classId,
              secondaryItemId: action.payload.params.classDayId,
              token: {
                coins: 5 * docs[0].data().points,
                silverTrophy: 0,
                goldTrophy: 0
              },
              metaData: { className: action.payload.params.className }
            },)
            userRewards[temp[i].userId] = {
              createdAt: new Date(),
              token: {
                coins: 5 * docs[0].data().points,
                trophies: {
                  silver: 0,
                  gold: 0
                }
              },
              ranked: false
            }
          }
        }
        const docSnap = await updateDoc(doc(db, "ClassPolls", docs[0].id), {
          isLive: false,
          userResponses: userRespons,
          userRewards: userRewards
        });
        return true
      } catch (err: any) {
        console.log(err)
        console.log(err.message)
        return false
      }
    }

    const data = yield call(end);
    if (data) {
      yield put(endPollSuccess(data));
    } else {
      yield put(endPollFailure("Something went wrong"));
    }
  } catch (err: any) {
    yield put(endPollFailure("Something went wrong"));
  }
}

export function* endPollSaga() {
  yield takeLatest(END_POLL_INIT, endPoll);
}