import { Box, Button, Modal } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addClassDay, resetClassDayResponses, updateClassDay } from "../../redux/classes/classesActions";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: "10px",
  boxShadow: 0,
  pt: 2,
  px: 4,
  pb: 3,
};

function ChildModal() {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen}>Open Child Modal</Button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className="bg-white bg-opacity-70 w-fit absolute top-[50%] left-[50%]">
          H
          <Button onClick={handleClose}>Close Child Modal</Button>
        </div>
      </Modal>
    </>
  );
}

const ScheduleModal = ({
  modalOpen,
  setModalOpen,
  modalContent,
  setModalContent
}: any) => {

  const [topic, setTopic] = useState<string>(modalContent?.topic || "");
  const [time, setTime] = useState<any>((new Date()).toTimeString().slice(0, 5));

  useEffect(() => {
    if (modalContent?.date) {
      setTopic(modalContent?.topic)
      setTime(modalContent?.date?.toDate().toTimeString()?.slice(0, 5))
    }
  }, [modalContent])
  const dispatch = useDispatch();

  const {
    isUpdatingClassDay,
    updateClassDayResp,
    updateClassDayRespStatus,
    updateClassDayError,
    updateClassDayErrorMsg
  } = useSelector((state: any) => state.classes)

  useEffect(() => {
    if (updateClassDayRespStatus === 200) {
      setTopic("")
      setTime((new Date()).toTimeString().slice(0, 5))
      setModalContent({})
      setModalOpen(false)
      dispatch(resetClassDayResponses());
    } else if (updateClassDayError) {
      alert(updateClassDayErrorMsg)
    }
  }, [isUpdatingClassDay]);

  const handleSave = () => {
    if (modalContent?.date) {
      dispatch(updateClassDay({
        classDayId: modalContent?.classDayId,
        date: modalContent.date?.toDate(),
        topic: topic,
        time: time
      }))
    } else {
      dispatch(addClassDay({
        date: new Date(),
        topic: topic,
        time: time
      }))
    }
  }

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setTopic("")
        setTime((new Date()).toTimeString().slice(0, 5))
        setModalContent({})
        setModalOpen(false)
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      {/* <Box sx={{ ...style, width: 400 }}>
        <h2 id="parent-modal-title">Text in a modal</h2>
        <p id="parent-modal-description">
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </p>
        <ChildModal />
      </Box> */}
      <div className="bg-white bg-opacity-70 rounded-[10px] w-fit absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] p-5">
        <div className="text-center text-xl mb-3">
          EDIT Class
        </div>
        <div>
          {`${modalContent.subjectName} - ${modalContent.containerDate}`}
        </div>
        <div className="flex flex-col gap-3 mt-2">
          <input type="text" value={topic} className="border-none outline-none bg-[#F6F6F6] px-3 py-1 rounded-[10px]" placeholder="Enter Topic" onChange={(e: any) => setTopic(e.target.value)} />
          <input type="time" value={time} className="border-none outline-none bg-[#F6F6F6] px-3 py-1 rounded-[10px]" placeholder="Enter Topic" onChange={(e: any) => setTime(e.target.value)} />
        </div>
        <div className="flex w-100 justify-center">
          <button className="border-none outline-none bg-white text-black px-5 py-2 rounded mt-5" onClick={handleSave}>
            {isUpdatingClassDay ? <div className="spinner-border animate-spin inline-block w-8 h-8 border-whtie-4 rounded-full" role="status">
            </div> : "Save"}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ScheduleModal;