import React, { useEffect, useRef, useState } from "react";
import { selectAppData, useHMSStore } from "@100mslive/react-sdk";
import { Box } from "@100mslive/react-ui";
// import { Chat } from "../components/Chat/Chat";
import { ParticipantList } from "../components/Header/ParticipantList";
import { StreamingLanding } from "../components/Streaming/StreamingLanding";
import { APP_DATA, SIDE_PANE_OPTIONS } from "../common/constants";
import Chat from "../components/chat";
import { off, onValue, ref } from "firebase/database";
import { realTimeDB } from "../firebaseConfig";
import { useUserAuth } from "../hooks/useAuthContextProvider";
import { useDispatch } from "react-redux";
import { getMentor } from "../redux/classes/classesActions";
import { useLocation } from "react-router-dom";
import { ToastManager } from "../components/Toast/ToastManager";

const SidePane = ({ css = {}, divRef, messages }: any) => {
  const location = useLocation()
  const sidepane = useHMSStore(selectAppData(APP_DATA.sidePane));
  let ViewComponent;
  if (sidepane === SIDE_PANE_OPTIONS.PARTICIPANTS) {
    ViewComponent = ParticipantList;
  } else if (sidepane === SIDE_PANE_OPTIONS.CHAT) {
    ViewComponent = Chat;
  } else if (sidepane === SIDE_PANE_OPTIONS.STREAMING) {
    ViewComponent = StreamingLanding;
  }
  if (!ViewComponent) {
    return null;
  }
  return (
    <Box
      css={{
        position: "absolute",
        w: "$100",
        h: "100%",
        p: "$10",
        bg: "$surfaceDefault",
        r: "$1",
        top: 0,
        right: "$10",
        zIndex: 10,
        boxShadow: "$md",
        ...css,
        "@lg": {
          w: "100%",
          right: 0,
          position: "fixed",
          bottom: 0,
          height: "unset",
          // ...(css["@lg"] || {}),
        },
      }}
    >
      {sidepane === SIDE_PANE_OPTIONS.CHAT ? <ViewComponent messages={messages} divRef={divRef} currentlyClickedClassDayId={location.state.classDayId} /> : <ViewComponent />}
    </Box>
  );
};

export default SidePane;
