import { useSelector } from "react-redux";

type AdminClassItemProps = {
  classDateContainer: any;
  setModalOpen: (val: boolean) => void;
  setModalContent: (val: any) => void;
  selectedClassDay: string;
  setSelectedClassDay: (val: string) => void;
  handleEdit: (val: any) => void;
  handleDelete: (val: any) => void;
}

const AdminClassItem = ({
  classDateContainer,
  setModalOpen,
  setModalContent,
  selectedClassDay,
  setSelectedClassDay,
  handleEdit,
  handleDelete
}: AdminClassItemProps) => {

  const handleAdd = (data: any) => {
    setModalContent({ containerDate: Object.keys(classDateContainer)[0] })
    setModalOpen(true)
  }

  const {
    isGettingSubjects,
    getSubjectsError,
    isGettingSubjectMentor,
    isDeletingClassDay
  } = useSelector((state: any) => state.classes)

  return (
    <div className="bg-white bg-opacity-70 p-5 rounded-[10px]">
      <div className="text-2xl mb-5">
        {Object.keys(classDateContainer)[0]}
      </div>
      {classDateContainer[Object.keys(classDateContainer)[0]].map((classDay: any) => {
        return (
          <div className="flex justify-between items-center mb-2">
            <div className="text-xl">
              {classDay.subjectName}
            </div>
            <div className="flex items-center gap-3">
              <div className="text-xl">
                {classDay.date.toDate().toLocaleString('en-US', { hour: 'numeric', minute: "numeric", hour12: true })}
              </div>
              {(!isGettingSubjects && !getSubjectsError) && <div className="bg-white text-black px-5 py-2 rounded cursor-pointer font-bold" onClick={() => {
                setSelectedClassDay(classDay.classDayId)
                handleEdit({ containerDate: Object.keys(classDateContainer)[0], ...classDay })
              }}>
                {(isGettingSubjectMentor && classDay.classDayId === selectedClassDay) ? <div className="spinner-border animate-spin inline-block w-8 h-8 border-whtie-4 rounded-full" role="status">
                </div> : "Edit"}
              </div>}
              <div className="bg-white text-black px-5 py-2 rounded cursor-pointer font-bold" onClick={() => {
                setSelectedClassDay(classDay.classDayId)
                handleDelete(classDay.classDayId)
              }}>
                {(isDeletingClassDay && classDay.classDayId === selectedClassDay && classDay.classDayId === selectedClassDay) ? <div className="spinner-border animate-spin inline-block w-8 h-8 border-whtie-4 rounded-full" role="status">
                </div> : "Delete"}
              </div>
            </div>
          </div>
        )
      })}
      {(!isGettingSubjects && !getSubjectsError) && <div className="flex justify-center">
        <div className="bg-white px-5 py-2 rounded cursor-pointer font-bold" onClick={handleAdd}>
          Add
        </div>
      </div>}
    </div>
  )
}

export default AdminClassItem;